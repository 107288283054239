import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/port'

class PortService extends ApiService {
    constructor(){
        super()
    }
    indexWithRequirements(){
        return http.get(`${this.getUrl()}?withrequirements=true`)
    }
    getUrl(){
        return URL
    }
    indexWithRepresentatives(){
        return http.get(`${this.getUrl()}?withrepresentatives=true`)
    }
}

export default new PortService()