import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/voyage-planning'

class VoyagePlanningService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
    getUnits(voyage, pol, pod, refresh = false, chosenOperator = null) {
        let query = `port_of_loading_id=${pol}&port_of_discharge_id=${pod}`;
        if(refresh) query += "&refresh=1";
        if(chosenOperator) query += ("&operator=" + chosenOperator);
        return http.get(`${this.getUrl()}/${voyage}?${query}`)
    }
    update(data) {
        return http.post('/api/job-unit/update-status', data)
    }
    updateOpenClosed(id, data) {
        return http.post(`${this.getUrl()}/${id}/update-status`, data)
    }
    updateAllocation(id, data) {
        return http.post(`${this.getUrl()}/${id}/allocation`, data)
    }
    updateAllCounts() {
        return http.get(`${this.getUrl()}/update-counts/all`)
    }
    downloadPermisList(id, data, pol, pod, isPermis) {
        return http.post(`${this.getUrl()}/${id}/download-permis-list`, {'data': data, 'pol': pol, 'pod': pod, 'is_permis': isPermis}, { responseType: 'blob' })
    }
}

export default new VoyagePlanningService()