import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/job-unit'

class JobUnitService extends ApiService {
    constructor(){
        super()
    }
    index(page, params, filters) {
        var queryUrl = '';
        let typeUrl = '';
        if (filters) {
            const port1 = filters[1] == 'export' ? filters[0] : filters[2];
            const port2 = filters[1] == 'import' ? filters[0] : filters[2];
            typeUrl = `loading_port=${port1}&discharge_port=${port2}`;
            queryUrl = '?' + typeUrl;
        }

        if (params && page) {
            if (filters) {
                typeUrl = '&' + typeUrl;
            }
            const queryParams = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
            queryUrl = `?page=` + page + typeUrl + `&` + queryParams
        }
        return http.get(`${this.getUrl()}` + queryUrl)
    }
    updateBookingJobUnit(data){
        return http.put(`${this.getUrl()}/job-booking-voyage/${data.id}`, data)
    }
    checkForDuplicates(id, chassis_numbers){
        return http.post(`${this.getUrl()}/check-for-dupelicates`, {'id': id, 'chassis_numbers': chassis_numbers.split('\n')})
    }
    getImportableUnits(pol, pod, customer_id, booking_id){
        return http.post(`${this.getUrl()}/get-importable-units`, {'pol': pol, 'pod': pod, 'customer_id': customer_id, 'booking_id': booking_id})
    }
    importUnits(unit_ids, booking_id){
        return http.post(`${this.getUrl()}/import-units`, {'units': unit_ids, 'booking_id': booking_id})
    }
    linkUnits(mainUnit, mode, isOnConnected, linkData){
        return http.post(`${this.getUrl()}/link-units`, {'main_unit': mainUnit, 'mode': mode, 'is_on_connected': isOnConnected, 'link_data': linkData})
    }
    unlink(unitId){
        return http.post(`${this.getUrl()}/unlink-units`, {'id': unitId})
    }
    getUrl(){
        return URL
    }
    updateDescription(id, description){
        return http.put(`${this.getUrl()}/update-description/${id}`, {'description': description})
    }
}

export default new JobUnitService()