<template>
  <div :class="changeVoyageIsOpen ? 'modal modal-in-modal show d-block' : 'd-none'" id="changeVoyageModal" tabindex="-1" aria-labelledby="changeVoyageModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
      <div class="modal-content adjustable">
        <div class="modal-header">
          <h5 class="modal-title" id="changeVoyageModalLabel">{{$t('operations.change_voyage')}}</h5>
          <button type="button" class="btn-close" @click.prevent="$parent.changeVoyageOpen(null, false)" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <h5 class="mb-1 fw-bold" @click="textX">{{$t('operations.select_itinerary')}}</h5>

          <template v-if="!searchingVoyages && filteredVoyages.length > 0">
              <div class="row fw-bold">
                  <div class="col-auto" style="width: 20px"> &nbsp; </div>
                  <div class="col-sm-4"> {{$tc('navigation.vessels', 1)}} / {{$tc('navigation.voyages', 1)}} </div>
                  <div class="col-sm-auto" style="width: 15%"> {{$tc('sales.loading_ports', 1)}} </div>
                  <div class="col-sm-auto" style="width: 15%"> {{$tc('sales.discharge_ports', 1)}} </div>
                  <div class="col-sm-auto" style="width: 20%"> {{$tc('navigation.carriers', 1)}} </div>
                  <div class="col-sm-auto" style="width: 12%"> {{$t('overview.status')}} </div>
              </div>
              <template v-for="voyageInfo in filteredVoyages" :key="voyageInfo.id">
                  <div class="row highlight-on-hover">
                      <div class="col-auto" style="width: 20px">
                          <FormItem :id="voyageInfo.id+'a'" :idField="voyageInfo.id" nameField="voyage" v-model="newVoyage" type="radio" 
                                    :disabled="voyageInfo.voyage_status_id != 1 && voyageInfo.voyage_status_id != 2" @onclick="newVoyageInfo = voyageInfo" />
                      </div>
                      <label class="col-sm-4"  :for="voyageInfo.id+'a'">
                          {{voyageInfo.vessel + ' ' + voyageInfo.number}}
                      </label>
                      <div class="col-sm-auto" style="width: 15%">
                          {{ voyageInfo.pol_date ? formatDate(voyageInfo.pol_date, "shortDate") : '-' }}
                      </div>
                      <div class="col-sm-auto" style="width: 15%">
                          {{ voyageInfo.pod_date ? formatDate(voyageInfo.pod_date, "shortDate") : '-' }}
                      </div>
                      <div class="col-sm-auto" style="width: 20%">
                          {{ findCarrier(voyageInfo) }}
                      </div>
                      <div :class="'col-sm-auto ' + (voyageInfo.voyage_status_id != 1 ? ' text-danger' : '')" style="width: 12%">
                          {{$t('voyage_status.' + voyageInfo.voyage_status_id)}}
                      </div>
                  </div>
              </template>
              <div v-if="mainReason">
                <p class="mt-3 mb-1 fw-bold">{{$t('form.remark')}}</p>
                <FormItem v-if="detailsReasons.length > 1" v-model="sameReasonForAll" id="detreason" type="checkbox" :label="$t('form.same_reason_for_all')" />
                <div class="py-1" style="padding-left: 34.5%" v-show="!sameReasonForAll">{{subReason}}</div>
                <div v-for="(detail, dindex) in detailsReasons" :key="detail.vin" class="row pb-2">
                    <template v-if="!sameReasonForAll || dindex == 0">
                    <div class="col-sm-4">{{sameReasonForAll ? subReason : detail.vin}}</div>
                    <div class="col-sm-8">
                        <FormItem type="textarea" v-model="detail.reason" />
                    </div>
                    </template>
                </div>
              </div>
          </template>
          <div v-else-if="searchingVoyages">{{$t('form.please_wait')}}</div>
          <div v-else>{{$t('form.no_available', [$tc('navigation.voyages', 2)])}}</div>
        </div>
        <div class="modal-footer d-flex">
          <button type="button" class="btn btn-success" @click.prevent="save" 
                 :disabled="!newVoyage || (mainReason && !sameReasonForAll && detailsReasons.find(dr => !dr.reason)) || (mainReason && sameReasonForAll && !detailsReasons[0].reason)">
                 {{$t('form.save')}}
            </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import jobService from '@/services/JobService';
    import voyageService from '@/services/VoyageService';
    export default {
        props: ['changeVoyageIsOpen'],
        name: 'VoyagePlanningChangeVoyagePopup',
        data() {
            return {
                filteredVoyages: {direct: [], transshipment: []},
                newVoyage: null,
                newVoyageInfo: null,
                mainReason: '',
                subReason: '',
                sameReasonForAll: 0,
                detailsReasons: [],
                port: null,
                job: null,
                fromJob: false,
                searchingVoyages: true,
                voyageName: ''
            }
           
        },
        methods: {
            reset(port, jobId, units = null){
                this.newVoyage = null;
                this.newVoyageInfo = null;
                this.mainReason = '';
                this.subReason = '';
                this.sameReasonForAll = 0;
                this.detailsReasons = [];
                this.port = port;
                this.filteredVoyages = {direct: [], transshipment: []};
                this.searchingVoyages = true;
                this.fromJob = false;
                this.voyageName = this.$parent.voyage.name;

                if(this.$parent.selectedUnitTab){
                    const selectedUnits = this.$parent.voyage.units[this.$parent.chosenPortId][port.code].filter(u => u.visible && u.selected);
                    this.setReason(selectedUnits, [this.$parent.selectedUnitTab]);
                    /*this.sameReasonForAll = selectedUnits.length == 1 ? 1 : 0;
                    if(this.$parent.selectedUnitTab == 1){
                        const reasons = [];
                        if(selectedUnits.find(u => u.is_on_hold)) reasons.push("On hold");
                        if(selectedUnits.find(u => !u.delivered_date)) reasons.push("Not delivered");
                        if(selectedUnits.find(u => !u.is_cleared)) reasons.push("Not cleared");
                        if(reasons.length > 0) this.mainReason = reasons.join(", ") + "\n";
                    }
                    else if(this.$parent.selectedUnitTab == 2){
                        this.mainReason = "No permit\n";
                    }
                    else{
                        this.mainReason = "Short shipment\n";
                    }
                    this.subReason = this.$parent.voyage.name + ' - ' + this.mainReason;

                    selectedUnits.forEach(unit => {
                        this.detailsReasons.push({'vin': unit.vin, 'reason': ''});
                    });*/
                }

                jobService.show(jobId).then(response => {
                    this.job = response.data;
                    let inputArray = [];
                    if(this.job.is_forward){
                        this.job.carriers.forEach((carr) => {
                            if(carr.id === this.job.quote.option_carrier_id){
                                inputArray.push({"port_of_loading_id": this.job.port_of_loading.id, "carrier_id": carr.carrier_id});
                            }
                        })
                    }
                    else{
                        this.job.carriers.forEach((carr) => {
                            inputArray.push({"port_of_loading_id": this.job.port_of_loading.id, "carrier_id": carr.carrier_id});
                        })
                    }
                    this.getVoyagesMultiple(inputArray);
                }).catch(error => {
                    console.log('error', error)
                });
            },
            resetWithJob(job, voyages, alreadyChosen = [], units = [], booking = {}){
                this.newVoyage = null;
                this.newVoyageInfo = null;
                this.mainReason = '';
                this.subReason = '';
                this.sameReasonForAll = 0;
                this.detailsReasons = [];
                this.filteredVoyages = JSON.parse(JSON.stringify(voyages));
                this.job = job;
                this.fromJob = true;
                let statuses = [];
                this.voyageName = booking.voyages && booking.voyages[0] ? booking.voyages[0].name : '';
                let newUnits = [...units];
                newUnits.forEach(unit => {
                    unit.is_cleared = 0;
                    unit.is_on_hold = 0;
                    unit.job_booking_voyage_job_units.forEach(jbvju => {
                        if(!statuses.includes(jbvju.status_id)){
                            statuses.push(jbvju.status_id);
                        }
                        if(jbvju.is_cleared){
                            unit.is_cleared = 1;
                        }
                        if(jbvju.is_on_hold){
                            unit.is_on_hold = 1;
                        }
                    });
                });
                this.setReason(newUnits, statuses);
                if(!this.filteredVoyages){
                    this.$parent.$refs.bookingCreate.getVoyagesMultiple(this, true);
                    return;
                }
                this.searchingVoyages = false;
                this.filteredVoyages.forEach(set => {
                    if(set.voyage_status_id == -1){
                        set.voyage_status_id = alreadyChosen.includes(set.id) ? -2 : 1;
                    }
                });
            },
            setReason(selectedUnits, selectedTabs){
                this.sameReasonForAll = selectedUnits.length == 1 ? 1 : 0;
                const reasons = [];
                if(selectedTabs.includes(1)){
                    if(selectedUnits.find(u => u.is_on_hold)) reasons.push("On hold");
                    if(selectedUnits.find(u => !u.delivered_date)) reasons.push("Not delivered");
                    if(selectedUnits.find(u => !u.is_cleared)) reasons.push("Not cleared");
                    if(reasons.length == 0 && !selectedTabs.includes(2)) reasons.push("No permit");
                }
                if(selectedTabs.includes(2)){
                    this.mainReason = reasons.push("No permit");
                }
                if(selectedTabs.includes(3) || selectedTabs.includes(4)){
                    this.mainReason = reasons.push("Short shipment");
                }
                if(reasons.length > 0) this.mainReason = reasons.join(", ") + "\n";
                this.subReason = this.voyageName + ' - ' + this.mainReason;

                selectedUnits.forEach(unit => {
                    this.detailsReasons.push({'vin': (unit.vin ? unit.vin : unit.chassis_number), 'reason': ''});
                });
                console.log(this.detailsReasons);
            },
            save(){
                const allReasons = [];
                this.detailsReasons.forEach(reason => {
                    const vinPart = this.detailsReasons.length == 1 ? '' : (' ' + reason.vin);
                    if(this.sameReasonForAll){
                        allReasons.push(this.voyageName + vinPart + ' - ' + this.mainReason + this.detailsReasons[0].reason);
                    }
                    else{
                        allReasons.push(this.voyageName + vinPart + ' - ' + this.mainReason + reason.reason);
                    }
                });
                this.$parent.bulkActionUnits(this.port, 'voyage', this.newVoyage, this.newVoyageInfo, allReasons.length > 0 ? allReasons : null);
            },
            findCarrier(voyageInfo){
                if(this.job == null) return '-';
                const carrier = this.job.carriers.find(carr => carr.carrier_id == voyageInfo.carrier_id);
                return carrier === undefined ? '-' : carrier.relation.name;
            },
            findDateForPort(ports, dateType){
                try{
                    let portId = dateType == 'date_arrival' ? 
                        (this.job.port_of_discharge.pivot ? this.job.port_of_discharge.pivot.id : this.job.port_of_discharge.id) : this.job.port_of_loading.id;
                    let port = ports.find(port => port.id == portId);
                    const date = port.pivot[dateType] ? port.pivot[dateType] : port.pivot['date_arrival'];
                    return date;
                }
                catch(e){
                    return "";
                }
            },
            getVoyagesMultiple(portsAndCarriers) {
                voyageService.searchVoyages({"port_of_discharge_id": this.job.port_of_discharge.id, "port_of_loading_ids": [this.job.port_of_loading.id]}).then(response => {
                    let directVoyages = [];
                    let transVoyages = [];
                    let alreadyChosen = [];
                    alreadyChosen.push(this.$parent.voyage.id);
                    let voyageSet = [];
                    for(let bundle of response.data.voyages[0].routes){
                        voyageSet.push(bundle.segments[0]);
                    }
                    voyageSet.forEach(set => {
                        if(alreadyChosen.includes(set.id)) set.voyage_status_id = -2;
                    });
                    directVoyages = directVoyages.concat( voyageSet.filter(set => !(set.id in alreadyChosen)) );

                    this.filteredVoyages = directVoyages;
                    this.filteredVoyages.sort((a,b) => {
                        return a.pol_date > b.pol_date;
                    });
                    console.log(this.filteredVoyages);
                }).catch(error => {
                    console.log('error', error)
                }).finally(e => {
                    this.$nextTick(() => {
                        this.searchingVoyages = false;
                    });
                })
            },
            setVoyageStatus(voyage){
                let newStatus = 1;
                if(!voyage.voyage_port_commodity_statuses) return newStatus;
                voyage.voyage_port_commodity_statuses.forEach(status => {
                    if(status.port_of_loading_id == this.job.port_of_loading_id && status.port_of_discharge_id == this.job.port_of_discharge_id){
                        this.selectedUnits.forEach(unit => {
                            if(unit.commodity.commodity_group_id == status.commodity_id){
                                newStatus = status.status != 'open' ? 2 : 1;
                                return;
                            }
                        });
                    }
                });
                return newStatus;
            },
        }
    }
</script>
<style scoped>
  .highlight-on-hover:hover{ background-color: #eee }
</style>