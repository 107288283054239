<template>
  <div :class="mode ? 'modal modal-in-modal show d-block' : 'd-none'" id="bookingActionModal" tabindex="-1" aria-labelledby="bookingActionModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
      <div class="modal-content adjustable">
        <div class="modal-header">
          <h5 class="modal-title" id="bookingActionModalLabel">{{$t('operations.' + mode)}}</h5>
          <button type="button" class="btn-close" @click.prevent="$parent.changeBookingOpen(false)" aria-label="Close"></button>
        </div>
        <div class="modal-body" v-if="loading"> <i class="fas fa-spinner fa-spin"></i> {{$t('form.please_wait')}} </div>
        <div class="modal-body" v-else-if="mode == 'mode_import_unit' && units.length == 0"> {{$t('form.no_found', [$t('navigation.units')])}} </div>
        <div class="modal-body" v-else-if="mode == 'mode_import_unit'">
          <h5 class="mb-1 fw-bold">{{$tc('navigation.units', 2)}}</h5>
          <div class="unit-table-sp" id="main-table">
            <table class="w-100">
              <tr class="unit-table-header">
                <th class="col-form-label-sm" style="width: 50px"></th>
                <th class="col-form-label-sm">{{ $t('navigation.commodities') }}</th>
                <th class="col-form-label-sm">{{ $t('navigation.makes') + ' & ' + $t('navigation.models') }}</th>
                <th class="col-form-label-sm">{{ $t('sales.vin') }}</th>
                <th class="col-form-label-sm">{{ $t('navigation.jobs') }}</th>
                <th class="col-form-label-sm">{{ $t('navigation.bookings') }}</th>
              </tr>
              <tr v-for="unit, index in units" :key="index" @click="checkUnit($event.target, unit.id)">
                <td class="text-ellipsis" style="width: 50px">
                  <FormItem :id="'importer-'+unit.id" nameField="unit" v-model="selectedUnits" type="checkbox-with-value" :content="unit.id" />
                </td>
                <td class="text-ellipsis">{{ unit.commodity.name }}</td>
                <td class="text-ellipsis">{{ unit.make.name + ' ' + unit.model.name }}</td>
                <td class="text-ellipsis">{{ unit.chassis_number }}</td>
                <td class="text-ellipsis">{{ unit.job.number }}</td>
                <td class="text-ellipsis">{{ unit.job_booking.number }}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="modal-body d-flex" v-else-if="mode == 'add_user'" style="min-height: 200px">
          <div class="w-50 pe-3">
            <FormItem id="user-adder" v-model="usersToAdd" type="livesearch-list" :options="users" :label="$t('sales.new_x', [$t('navigation.users')])" />
          </div>
          <div class="w-50 ps-3">
            <p class="fw-bold">Active users</p>
            <div class="text-muted" v-for="user in $parent.job.users" :key="user.id">{{user.user.name}}</div>
          </div>
        </div>
        <div class="modal-body" v-else>
          <h5 class="mb-1 fw-bold">{{phase == 1 ? "Select main unit" : "Select " + (mode == 'mode_connect_units' ? "unit to connect to" : "stacked units")}}</h5>
          <div class="unit-table-sp" id="main-table">
            <table class="w-100">
              <tr class="unit-table-header">
                <th class="col-form-label-sm" style="width: 50px"></th>
                <th class="col-form-label-sm">{{ $t('navigation.commodities') }}</th>
                <th class="col-form-label-sm">{{ $t('navigation.makes') + ' & ' + $t('navigation.models') }}</th>
                <th class="col-form-label-sm">{{ $t('sales.vin') }}</th>
              </tr>
              <tr v-for="unit, index in units" :key="index" @click="checkUnit($event.target, index)" v-show="!(unit.phase_one_only && phase != 1)">
                <td class="text-ellipsis" :style="{'width': '50px', 'paddingLeft': (unit.phase_one_only ? '10px' : '0px')}">
                  <FormItem v-if="phase == 1 && unit.phase_one_only" :id="'importer-'+index" nameField="unit" v-model="selectedUnit" type="radio" :idField="unit.id" :disabled="unit.off_limits_p1" @onclick="isOnConnected = 1" />
                  <FormItem v-else-if="phase == 1 && !unit.phase_one_only" :id="'importer-'+index" nameField="unit" v-model="selectedUnit" type="radio" :idField="unit.id" :disabled="unit.off_limits_p1" @onclick="isOnConnected = 0" />
                  <FormItem v-else-if="phase == 2 && mode == 'mode_connect_units'" :id="'importer-'+index" nameField="unit" v-model="unitToLink" type="radio" :idField="unit.id" :disabled="unit.id == selectedUnit || unit.off_limits_p2" />
                  <FormItem v-else-if="phase == 2 && mode == 'mode_stacked_unit'" :id="'importer-'+index" nameField="unit" v-model="unitsToStack" type="checkbox-with-value" :content="unit.id" :disabled="unit.id == selectedUnit || unit.off_limits_p2" />
                </td>
                <td class="text-ellipsis">{{ unit.commodity.name }}</td>
                <td class="text-ellipsis">{{ unit.make.name + ' ' + unit.model.name }}</td>
                <td class="text-ellipsis">{{ unit.chassis_number }}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="modal-footer d-flex" v-if="mode == 'mode_import_unit'">
          <FormItem type="download" :label="$t('form.save')" :service="save" :disabled="checkDisabled" :options="{send: true}" startTag="far fa-save" />
        </div>
        <div class="modal-footer d-flex" v-else-if="mode == 'add_user'">
          <FormItem type="download" :label="$t('form.save')" :service="save" :disabled="usersToAdd.length == 0" :options="{send: true}" startTag="far fa-save" />
        </div>
        <div class="modal-footer d-flex" v-else-if="phase == 1">
          <button type="button" class="btn btn-primary" @click="phase = 2" :disabled="!selectedUnit">&gt; {{$t('form.nav_next')}}</button>
        </div>
        <div class="modal-footer d-flex justify-content-between" v-else-if="phase == 2">
          <button type="button" class="btn btn-secondary" @click="phase = 1">&lt; {{$t('form.nav_prev')}}</button>
          <FormItem type="download" :label="$t('form.save')" :service="save" :disabled="checkDisabled" :options="{send: true}" startTag="far fa-save" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import jobUnitService from '@/services/JobUnitService';
    import jobService from '@/services/JobService';
    import UserService from '@/services/UserService';
    export default {
        name: 'JobBookingActionPopup',
        data() {
            return {
                mode: false,
                loading: false,
                units: [],
                users: [],
                selectedUnits: [],
                selectedUnit: null,
                unitsToStack: [],
                unitsToConnectStack: [],
                usersToAdd: [],
                unitToLink: null,
                bookingId: null,
                isOnConnected: false,
                phase: 1,
                userService: UserService
            }
        },
        computed: {
            checkDisabled(){
              return this.mode == 'mode_import_unit' ? this.selectedUnits.length == 0 : (this.unitsToStack.length == 0 && this.unitsToConnectStack.length == 0 && !this.unitToLink);
            }
        },
        methods: {
            setMode(mode){
              this.mode = mode;
              this.units = [];
              this.selectedUnits = [];
              this.selectedUnit = null;
              this.unitsToStack = [];
              this.unitsToConnectStack = [];
              this.unitToLink = null;
              this.usersToAdd = [];
              const booking = this.$parent.job.bookings[this.$parent.selectedBooking];
              this.bookingId = booking.id;
              if(mode == 'mode_import_unit'){
                const pol = booking.job_booking_voyage[0].port_of_loading_id;
                const pod = booking.job_booking_voyage.at(-1).port_of_discharge_id;
                this.loading = true;
                this.phase = 0;
                jobUnitService.getImportableUnits(pol, pod, this.$parent.job.customer_id, booking.id).then(response=> {
                  this.units = response.data;
                  this.units.sort((a, b) => a.job.number > b.job.number ? -1 : 1);
                  this.loading = false;
                });
              }
              else if(this.mode == 'add_user'){
                this.users = this.userService.indexFilteredByOffice(this.$parent.userCustomer.office_id).then(response => {
                  this.users = response.data.filter(user => this.$parent.job.users.findIndex(jobUser => jobUser.user.id == user.id) == -1);
                });
              }
              else{
                this.phase = 1;
                this.units = [...booking.job_unit];
                if(this.mode == 'mode_stacked_unit'){
                  for(let i = (this.units.length - 1); i >= 0; i--){
                    const connectedUnit = this.units[i].stacked_units.find(stacked => stacked.stacking_type == 'connected');
                    if(connectedUnit){
                      connectedUnit.stacked_units = [];
                      connectedUnit.phase_one_only = true;
                      this.units.splice(i+1, 0, {...connectedUnit});
                    }
                  }
                }
                this.units.forEach(unit => {
                  unit.is_linked = unit.stacked_units.find(stacked => stacked.stacking_type == 'connected') ? true : false;
                  unit.is_stacked = unit.stacked_units.find(stacked => stacked.stacking_type == 'stacked') ? true : false;
                  unit.off_limits_p1 = unit.is_linked && mode == 'mode_connect_units' && unit.stacked_units.length > 0;
                  unit.off_limits_p2 = unit.is_linked  || unit.phase_one_only || mode == 'mode_connect_units' && unit.stacked_units.length > 0;
                });
              }
            },
            checkUnit(el, id){
              if(el.tagName == 'INPUT'){
                this.$forceUpdate();
                return;
              }
              document.querySelector('#importer-'+id).click();
              this.$forceUpdate();
            },
            save(){
              const aftersave = function(data, parent, updateOverview = false, resetUnitDescriptions = false){
                parent.reset();
                parent.setData(data, false, resetUnitDescriptions);
                parent.changeBookingOpen(false);
                if(updateOverview){
                  parent.$parent.getIndex();
                }
              }
              if(this.mode == 'mode_import_unit'){
                return jobUnitService.importUnits(this.selectedUnits, this.bookingId).then(response => aftersave(response.data, this.$parent, true));
              }
              else if(this.mode == 'add_user'){
                return jobService.addUsers(this.usersToAdd, this.$parent.job.id).then(response => aftersave(response.data, this.$parent));
              }
              const linkData = this.mode == 'mode_connect_units' ? this.unitToLink : this.unitsToStack;
              return jobUnitService.linkUnits(this.selectedUnit, this.mode, this.isOnConnected, linkData).then(response => aftersave(response.data, this.$parent, true, true));
            }
        }
    }
</script>
<style scoped>
  tr:not(.unit-table-header):hover{ background-color: #eee }
</style>