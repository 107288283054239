<template>
<div v-if="formActive" id="voyageMainForm">
    <div class="d-flex mb-2">
        <strong class="w-50">{{$t('navigation.general_details')}}</strong>
        <strong class="w-50">{{$t('legacy.legacy_options')}}</strong>
    </div>
    <div class="d-flex">
        <div class="w-50 pe-4">
            <FormItem id="id_vessel" :label="$t('navigation.vessels')" type="livesearch" v-model="voyage.vessel_id" :service="vesselService" :content="voyage.vessel ? voyage.vessel.name : ''" @onchange="(a,b,c) => setVessel(c)" :required="true" />
            <FormItem id="voyagenumber" :label="$t('overview.voyage_number')" v-model="voyage.number" type="text-voyage" :required="true" @onchange="findVoyage" @input="voyage.number = $event.target.value.toUpperCase()"/>
            <div v-if="possibleVoyageFound.id != null" style="margin-left: 35%">
                <p>{{ $tc('operations.voyage_check', possibleVoyageFound.index) }} <a href="#" @click="getVoyage(possibleVoyageFound.id)">{{possibleVoyageFound.code}}</a></p>
            </div>
            <FormItem id="regionsearch" :label="$t('overview.type')" v-model="voyage.region_type_id" type="buttongroup" :options="region_types" :required="true" />
            <FormItem id="carrier_id" :label="$t('navigation.carriers')" type="livesearch" v-model="voyage.carrier_id" :options="carriers" :content="voyage.carrier && voyage.carrier.relation ? voyage.carrier.relation.name : ''"
                     @onchange="getViaPorts(voyage.carrier_id)" :required="true" :disabled="disabledUntilConfirmed" />
            <FormItem id="opby" :label="$t('operations.operated_by')" type="livesearch-list" v-model="voyage.operators" :options="offices" :content="voyage.operators ? voyage.operators.name : ''" 
                     :required="true" @add="addRelColumn" @delete="deleteRelColumn" :disabled="disabledUntilConfirmed" />
            <div class='d-flex'>
                <div class="col-form-label col-form-label-sm" style="width: 34.5%">{{$t('operations.voyage_options')}}</div>
                <div class="legacy-options">
                    <template v-for="value, key in voyage.legacy_options" :key="key">
                        <template v-if="['only_bookable_by_hq', 'hide_on_website', 'hide_for_customers', 'show_carrier_reference'].includes(key)">
                            <FormItem :id="'legacy-option-'+key" :label="$t('legacy.' + key.toLowerCase())" v-model="voyage.legacy_options[key]" type="checkbox" sizeLabel="8" sizeItem="4" />
                        </template>
                    </template>
                </div>
            </div>
        </div>

        <div class="w-50 pe-4">
            <FormItem id="voyage_status_id" label="Booking status" v-model="voyage.voyage_status_id" type="buttongroup" :options="voyageStatusses" />
            <FormItem id="shipload_status_id" label="Shipload status" v-model="voyage.legacy_options['shipload_status_id']" type="buttongroup" :options="modifiedVoyageStatusses" />
            <div class="d-flex">
                <div style="width: 34.5%"></div>
                <FormItem id="main_doc" label="Manage documentation in Mainport" v-model="voyage.manage_documentation_in_mainport" type="checkbox" />
            </div>
            <div class="d-flex">
                <div style="width: 34.5%"></div>
                <FormItem id="main_doc_2" label="Manage booking in Mainport" v-model="voyage.manage_booking_in_mainport" type="checkbox" />
            </div>
            <FormItem id="currency" label="Manifest currency" v-model="voyage.legacy_options['currency']" type="select" :options="manifestCurrencies" />
            <div class="row mb-2">
                <label for="" class="col-form-label col-form-label-sm col-sm-4">Service</label>
                <div class="btn-group btn-group-sm col-sm-8">
                    <voyage-legacy-service-form-component :initialLegacyServices="voyage.legacy_services" :legacyServices="legacyServices" v-on:legacyservicechange="setLegacyServices" ref="legacyservicesform" />
                </div>
            </div>
            <template v-if="filteredPortsLength > 0">
                <div class="row">
                    <strong class="d-block col-4">{{$t('operations.representatives_port')}}</strong>
                    <div v-for="operator in voyage.operators" :key="operator.id" class="col-auto" :style="{'width' : ((66.6 / voyage.operators.length) + '%')}">
                        <div>{{operator.code}}</div>
                    </div>
                </div>
                <div class="row mb-1 portrep-select" v-for="(port, id) in portRepData" :key="id">
                    <template v-if="portsById[id]">
                        <div class="col-4 col-form-label col-form-label-sm">{{portsById[id].name}}</div>
                        <div v-for="op in voyage.operators" :key="op.id" class="col-auto" :style="{'width' : ((66.6 / voyage.operators.length) + '%')}">
                            <FormItem v-model="port[op.id]" type="select" :options="portsById[id].representatives" />
                        </div>
                    </template>
                </div>
            </template>

            <template v-if="!voyage.manage_documentation_in_mainport">
                <div class="legacy-options w-50" >
                    <template v-for="value, key in voyage.legacy_options" :key="key">
                        <template v-if="(key != 'id' && key.length == 2)">
                            <FormItem :id="'legacy-option-'+key" :label="$t('legacy.' + key.toLowerCase())" v-model="voyage.legacy_options[key]" type="checkbox" sizeLabel="auto" />
                        </template>
                    </template>
                </div>
                <FormItem :id="'legacy-option-not_sync_main'" :label="`Do not sync main voyage to Shipload`" v-model="voyage.legacy_options['not_sync_main']" type="checkbox" @onclick="setSync = false" />
            </template>
        </div>
    </div>

    <voyage-port-form-component :initialVoyageport="voyage.ports" :ports="ports" v-on:voyageportchange="setVoyageport" ref="portform"></voyage-port-form-component>

    <div class="d-flex justify-content-center my-4" v-if="fromJobOrQuote">
        <button class="btn btn-success btn-sm w-25" type="button" @click="setManualVoyage" :disabled="submitted">
            <i class="fa fa-save"></i> &ensp;{{ $t('form.save') }}
        </button>
    </div>

    <dialog id="confirm-dialog">
        <p class="py-4">Content is shown here</p>
        <form method="dialog" class="d-flex justify-content-between">
            <button class="btn btn-success col-5" @click="confirmDialog(true)">{{ $t('form.yes') }}</button>
            <button class="btn btn-danger col-5" @click="confirmDialog(false)">{{ $t('form.no') }}</button>
        </form>
    </dialog>

</div>
</template>

<script>
    import relationservice from '@/services/RelationService'
    import vesselservice from '@/services/VesselService'
    import portservice from '@/services/PortService'
    import carrierservice from '@/services/CarrierService'
    import voyageservice from '@/services/VoyageService'
    import dataStore from '@/store/data';
    import legacyservicesservice from '@/services/LegacyservicesService'
    import VoyagePortFormComponent from '../voyageport/VoyagePortFormComponent.vue'
    import VoyageLegacyServiceFormComponent from './VoyageLegacyServiceFormComponent.vue'

    export default {
        name: 'VoyageListComponent',
        components: {
            VoyagePortFormComponent,
            VoyageLegacyServiceFormComponent
        },
        props: ['itemData', 'fromJobOrQuote', 'setPending'],
        data() {
            return {
                voyage: {
                    'number': null,
                    'carrier_reference': 0,
                    'region_type_id': '',
                    'carrier_id': '',
                    'voyage_status_id': 1,
                    'vessel_id': '',
                    'code': '',
                    'ports': [],
                    'manage_documentation_in_mainport': 1,
                    'manage_booking_in_mainport': 0,
                    'legacy_options': {
                        'CN': 0,
                        'DE': 0,
                        'DX': 0,
                        'ES': 0,
                        'FR': 0,
                        'FN': 0,
                        'OC': 0,
                        'SE': 0,
                        'UK': 0,
                        'only_bookable_by_hq': 0,
                        'hide_on_website': 0,
                        'hide_for_customers': 0,
                        'show_carrier_reference': 0,
                        'currency': null,
                        'shipload_status_id': 1
                    },
                    'legacy_services': [],
                    'operators': []
                },
                manifestCurrencies: [ {id: 'USD', name: 'USD'}, {id: 'EUR', name: 'EUR'}, {id: 'AUD', name: 'AUD'} ],
                portRepData: {},
                shipload_status_id: null,
                region_types: [],
                carriers: [],
                voyageStatusses: [],
                ports: [],
                offices: [],
                representatives: [],
                portsById: {},
                possibleVoyageFound: { id: null, code: '' },
                filteredPortsLength: 0,
                viaPorts: [],
                legacyServices: [],
                submitted: false,
                loading: false,
                invalid: {},
                dataItemsLoaded: 0,
                dataItemsToLoad: 3,
                isEditing: false,
                isModifying: false,
                setModalOK: false,
                setSync: true,
                disabledUntilConfirmed: false,
                vesselService: vesselservice,
                relationService: relationservice,
                formActive: false
            }
        },
        computed: {
            modifiedVoyageStatusses() {
                console.log(this.voyageStatusses);
                if (this.voyage.manage_documentation_in_mainport === 1) {
                    return this.voyageStatusses.filter(item => item.id !== 4);
                } 
                
                return this.voyageStatusses;
                
            }
        },
        watch: {
            'voyage.manage_documentation_in_mainport': function (newVal) {
                console.log("manage_documentation_in_mainport", newVal);
                console.log("shipload_status_id", this.voyage.legacy_options['shipload_status_id']);
                if (newVal === 1 && this.voyage.legacy_options['shipload_status_id'] == 4) {
                this.voyage.legacy_options['shipload_status_id'] = 1;
                }
            },
            itemData: function (val) {
                this.voyage = val    
            },
        },
        methods: {
            active(value){
                this.formActive = value;
            },
            validate(){
                if(this.setModalOK){
                    this.setModalOK = false;
                    return true;
                }
                let valid = this.voyage.region_type_id != '';
                if(!valid) {
                    alert("No voyage type was chosen");
                    return valid;
                }
                valid = this.voyage.operators.length > 0;
                if(!valid) {
                    alert("You must choose at least one operator");
                    return valid;
                }
                if(this.fromJobOrQuote && this.$parent.jobItem){
                    let hasAllReqPorts = true;
                    this.$refs.portform.requiredPorts.forEach(rport => {
                        if(!this.voyage.ports.find(aport => aport.pivot.port_id == rport.id)){
                            hasAllReqPorts = false;
                        }
                    })
                    if(!hasAllReqPorts){  
                        alert("You must choose all required ports");
                        return false;
                    }
                }

                let required = document.querySelectorAll('#voyageMainForm input:not(.dp__input):not(.multi-list):not([type="checkbox"]), #voyageMainForm .portrep-select select, .date-validation input');
                for(let field of required) {
                    if(!field.value || field.value == this.$t('form.select')){
                        valid = false;
                        field.classList.add("is-invalid");
                    }
                    else{
                        field.classList.remove("is-invalid");
                    }
                }
                if(!valid) {
                    alert(this.$t('errors.empty_fields'));
                    return false;
                }
                required = document.querySelectorAll('.date-validation input');
                let length = required.length;
                for(let i = 0; i < length; i++) {
                    let port = this.voyage.ports[i];
                    if(port.pivot.date_departure === '' || port.pivot.date_departure === null) continue;
                    if(port.pivot.date_arrival.substring(0, 10) > port.pivot.date_departure){
                        valid = false;
                        required[i].classList.add("is-invalid");
                    }
                    else{
                        required[i].classList.remove("is-invalid");
                    }
                }
                let hasNewPorts = false;
                if(!valid) {
                    alert(this.$t('errors.date_inverse'));
                }
                else{
                    this.voyage.ports.forEach((port) => {
                        if(port.pivot.id == null) delete port.pivot.id;
                        if(port.port_voyage_via_port === undefined){
                            port.port_voyage_via_port = [];
                        }
                        port.port_voyage_via_port.forEach((viaport) => {
                            if(viaport.new_recurring) hasNewPorts = true;
                        });
                    });
                }
                if(hasNewPorts){
                    const carrierName = document.querySelector('#carrier_id_wrapper input').value;
                    let confirmText = "";
                    this.voyage.ports.forEach((port) => {
                        let viaportNames = [];
                        port.port_voyage_via_port.forEach((viaport) => {
                            if(viaport.new_recurring){
                                viaportNames.push(viaport.name);
                                console.log(viaport);
                            }
                        });
                        if(viaportNames.length == 0) return;
                        const portName = port.name ? port.name : this.ports.find(aport => aport.id == port.pivot.port_id).name;
                        confirmText += (this.$t('operations.add_via_port_message', [viaportNames.join(', '), portName, carrierName]) + '\n');
                    });
                    this.setConfirmBox(confirmText);
                    return false;
                }
                if(valid){
                    this.voyage.representatives_data = this.portRepData;
                }
                return valid;
            },
            getData() {
                return this.voyage;
            },
            setData(data, isModifying = true) {
                if(data){
                    this.disabledUntilConfirmed = false;
                    this.isModifying = isModifying;
                    this.voyage = data;
                    this.voyage.operators.forEach(op => {
                        op.name = op.relation.name;
                        op.code = op.relation.addresses[0] ? ('NMT ' + op.relation.addresses[0].country.iso) : "???";
                    });
                    this.filteredPorts = this.voyage.ports.filter(p => p.representatives.length > 1);
                    this.filteredPorts.forEach(port => {
                        if(!this.portRepData[port.id]) this.portRepData[port.id] = {};
                        this.voyage.operators.forEach(op => {
                            this.portRepData[port.id][op.id] = null;
                        });
                    });
                    this.filteredPortsLength = this.filteredPorts.length;
                    this.setSync = true;
                    if(!data.legacy_options){
                        this.voyage.legacy_options = this.getDefaultLegacyOptions();
                    }
                    else{
                        //Sorts the legacy options alphabetically
                        this.voyage.legacy_options = Object.keys(this.voyage.legacy_options).sort().reduce(
                            (obj, key) => { 
                                obj[key] = this.voyage.legacy_options[key]; 
                                return obj;
                            }, {}
                        );
                    }
                    
                    data.port_voyages.forEach( pv => {
                        const voyagePort = data.ports.find(port => port.pivot.port_id == pv.port_id);
                        if(voyagePort) {
                            voyagePort.pivot.date_loaded = pv.date_loaded ? this.formatDate(pv.date_loaded, 'shortDate') : '';
                            voyagePort.port_voyage_via_port = pv.port_voyage_via_port;
                            const size = voyagePort.port_voyage_via_port.length - 1;
                            for(let i = size; i >= 0; i--){
                                let pvvp = voyagePort.port_voyage_via_port[i];
                                if(pvvp.to_port){
                                    pvvp.name = pvvp.to_port.name;
                                    pvvp.active = 1;
                                }
                                else{
                                    voyagePort.port_voyage_via_port.splice(i, 1);
                                }
                            }
                        }
                        pv.representatives.forEach(rep => {
                            if(this.portRepData[pv.port_id] && this.portRepData[pv.port_id][rep.office_id] !== undefined){
                                this.portRepData[pv.port_id][rep.office_id] = rep.representative_id;
                            }
                        });
                    })
                    this.getViaPorts(data.carrier_id, true);
                    
                    this.voyage.ports.forEach(port => {
                        let replist = [];
                        let repids = [];
                        port.cId = port.id;
                        port.representatives.forEach(rep => {
                            if(!repids.includes(rep.id)){
                                repids.push(rep.id);
                                replist.push({
                                    id: rep.id,
                                    name: rep.name,
                                    office_id: rep.office_id != null ? rep.office_id : 2,
                                    voyage_id: this.voyage.id
                                });
                            }
                        });
                        port.representatives = replist;
                    });
                }
                if(!this.formActive) return;
                this.possibleVoyageFound = { id: null, code: '' };
            },
            getCarriers() {
                relationservice.index(null,null,'carrier').then(response => {
                    this.carriers = response.data;
                    this.dataItemsLoaded++;
                    this.checkData();
                }).catch(error => console.log('error', error) );
            },
            getOffices() {
                relationservice.index(null, null, 'office-branches').then(response => {
                    this.offices = [];
                    response.data.forEach(dat => {
                        this.offices.push({
                            id: dat.office.id,
                            name: dat.name,
                            code: dat.addresses[0] ? ('NMT ' + dat.addresses[0].country.iso) : '???'
                        })
                    });
                    this.dataItemsLoaded++;
                    this.checkData();
                }).catch(error => {
                    console.log('error', error)
                })
            },
            getPortReps(item){
                if(item && !this.portRepData[item.id] && item.representatives.length > 1){
                    this.portRepData[item.id] = {};
                    this.voyage.operators.forEach(op => {
                        this.portRepData[item.id][op.id] = null;
                    });
                    this.filteredPortsLength++;
                }
            },
            removePortReps(id){
                if(this.portRepData[id]){
                    this.filteredPortsLength--;
                    delete this.portRepData[id];
                }
            },

            setVoyageport(voyageport) {
                this.voyage.ports = voyageport;
            },
            setLegacyServices(legacy_services) {
                if (legacy_services != undefined) {
                    this.voyage.legacy_services = legacy_services;
                } else {
                    this.voyage.legacy_services = [];
                }
            },
            
            setVessel(item){
                if(item){
                    this.voyage.number = '';
                    if(!this.isModifying) this.findVoyage();
                    this.voyage.vessel = item;
                    this.voyage.number = item.code;
                }
                else{
                    this.voyage.vessel = null;
                    this.voyage.number = '';
                }
            },
            getPorts() {
                portservice.indexWithRepresentatives().then(response => {
                    this.ports = response.data
                    response.data.forEach(port => this.portsById[port.id] = port );
                    this.dataItemsLoaded++;
                    this.checkData();
                }).catch(error => console.log('error', error) );
            },
            getLegacyservices() {
                this.legacyServices = legacyservicesservice.getLegacyservices()
            },
            reset(isEditing = false, isModifying = false) {
                this.isEditing = isEditing;
                this.isModifying = isModifying;
                this.disabledUntilConfirmed = !isEditing;
                this.setSync = true;
                this.portRepData = {};
                this.filteredPorts = [];
                this.filteredPortsLength = 0;
                if(this.dataItemsLoaded < this.dataItemsToLoad){
                    this.loadData();
                    return;
                }

                if(!this.formActive) return;

                this.voyage = {
                    'number': null,
                    'carrier_reference': 0,
                    'region_type_id': '',
                    'carrier_id': null,
                    'voyage_status_id': 1,
                    'vessel_id': null,
                    'code': null,
                    'ports': [],
                    'legacy_services': [],
                    'operators': [],
                    'manage_documentation_in_mainport': 1,
                    'manage_booking_in_mainport': 0,
                    'legacy_options': this.getDefaultLegacyOptions()
                }
                this.loading = false
                this.invalid = {}
                this.possibleVoyageFound = { id: null, code: '' };
                if(this.fromJobOrQuote){
                    let portsToAdd = [];
                    if(this.$parent.jobItem){
                        portsToAdd.push(this.$parent.jobItem.port_of_loading);
                        portsToAdd.push(this.$parent.jobItem.port_of_discharge);
                        this.$nextTick(() => this.$refs.portform.requiredPorts = portsToAdd);
                    }
                    else{
                        portsToAdd = portsToAdd.concat(this.$parent.quotation.ports_of_loading).concat(this.$parent.quotation.ports_of_discharge);
                        this.$nextTick(() => this.$refs.portform.suggestedPorts = portsToAdd);
                    }
                }
                if(!isEditing) this.$nextTick(() => this.$refs.portform.addOption());
            },
            getDefaultLegacyOptions(){
                return {
                    'CN': 0,
                    'DE': 0,
                    'DX': 0,
                    'ES': 0,
                    'FR': 0,
                    'FN': 0,
                    'OC': 0,
                    'SE': 0,
                    'UK': 0,
                    'only_bookable_by_hq': 0,
                    'hide_on_website': 0,
                    'hide_for_customers': 0,
                    'show_carrier_reference': 0,
                    'currency': null,
                    'shipload_status_id': 1
                };
            },
            findVoyage(){
                if(this.voyage.number) this.voyage.number.toUpperCase();
                voyageservice.findVoyage(this.voyage.vessel_id, this.voyage.number).then(response => {
                    if(response.data.voyage_to_load){
                        this.reset(true);
                        this.setData(response.data.voyage_to_load, false);
                        window.location.hash = response.data.voyage_to_load.id;
                        if(response.data.other_voyage){
                            this.possibleVoyageFound = response.data.other_voyage;
                            this.possibleVoyageFound.index = 2;
                        }
                    }
                    else{
                        const savedData = {vessel_id: this.voyage.vessel_id, number: this.voyage.number, vessel: this.voyage.vessel};
                        if(!this.isModifying) this.reset(false);
                        this.voyage.vessel_id = savedData.vessel_id, this.voyage.vessel = savedData.vessel, this.voyage.number = savedData.number;
                        this.possibleVoyageFound = response.data;
                        this.possibleVoyageFound.index = 1;
                        this.disabledUntilConfirmed = this.possibleVoyageFound.code == savedData.number;
                        window.location.hash = '';
                    }
                }).catch(error => console.log('error', error) );
            },
            getVoyage(id){
                window.location.hash = id;
                window.location.reload();
            },
            getViaPorts(id, autoFillInViaPorts = false){
                if(typeof id != 'number') return;
                carrierservice.getViaPorts(id).then(response => {
                    this.viaPorts = response.data;
                    if(autoFillInViaPorts && this.viaPorts.length > 0){
                        this.voyage.ports.forEach(port => {
                            const viaports = this.getViaPortsForId(port.id);
                            viaports.forEach(viaport => {
                                const findActivePort = port.port_voyage_via_port.find(pvvp => pvvp.to_port_id == viaport.pivot.port_id);
                                if(findActivePort) {
                                    findActivePort.active = 1;
                                    findActivePort.is_new = false;
                                }
                                else{
                                    port.port_voyage_via_port.push({
                                        'id': viaport.pivot.port_id,
                                        'is_new': false,
                                        'name': viaport.name,
                                        'active': 0,
                                        'temp_id': Math.random()
                                    });
                                }
                            });
                        });
                    }
                    else{
                        this.voyage.ports.forEach(port => {
                            const viaports = this.getViaPortsForId(port.id);
                            const vlength = viaports.length - 1;
                            for(let i = vlength; i >= 0; i--){
                                const viaport = viaports[i];
                                port.port_voyage_via_port.push({
                                    'id': viaport.pivot.id,
                                    'is_new': false,
                                    'name': viaport.name,
                                    'active': 0,
                                    'temp_id': Math.random()
                                });
                            }
                        });
                    }
                }).catch(error => {
                    console.log('error', error)
                })
            },
            getViaPortsForId(id){
                const port = this.viaPorts.find(viaPort => viaPort.port_id == id);
                return port ? port.carrier_to_ports : [];
            },
            setDoNotSync(e){
                if(e.target.checked && this.setSync) this.voyage.legacy_options.not_sync_main = true;
            },
            setConfirmBox(text){
                const box = document.getElementById('confirm-dialog');
                box.showModal();
                box.children[0].innerHTML = text;
            },
            confirmDialog(setViaPortsDefault){
                if(!setViaPortsDefault){
                    this.voyage.ports.forEach((port) => {
                        if (port.port_voyage_via_port) {
                            port.port_voyage_via_port.forEach((viaport) => {
                                if(viaport.new_recurring) viaport.new_recurring = false;
                            });
                        }
                    });
                }
                this.setModalOK = true;
                this.$nextTick(() => this.$parent.$parent.save());
            },
            addRelColumn(id, name){
                const index = this.voyage.operators.findIndex(op => op.id == id);
                const item = this.offices.find(office => office.id == id);
                this.voyage.operators[index].code = item.code;
                for(let port in this.portRepData){
                    this.portRepData[port][id] = null;
                }
            },
            deleteRelColumn(e, c, item){
                for(let port in this.portRepData){
                    delete this.portRepData[port][item.id];
                }
            },
            setManualVoyage(){
                if(!this.validate()){
                    return;
                }
                this.submitted = true;
                if(!this.voyage.id){
                    if(this.setPending){
                        this.voyage.is_pending = true;
                    }
                    voyageservice.store(this.voyage).then(response => {
                        this.voyage.id = response.data.id;
                        const parent = this.$parent;
                        if(this.setPending){
                            this.$parent.$refs.voyageComp.getVoyages();
                            this.reset();
                        }
                        else{
                            const newVoyage = {
                                id: response.data.id,
                                pod_date: this.voyage.ports[1].pivot.date_arrival,
                                pol_date: this.voyage.ports[0].pivot.date_departure ? this.voyage.ports[0].pivot.date_departure : this.voyage.ports[0].pivot.date_arrival,
                                vessel: this.voyage.vessel.name,
                                number: response.data.number
                            };
                            parent.setItinerary(newVoyage);
                            newVoyage.carrier = this.carriers.find(carr => carr.id == response.data.carrier_id).name;
                            newVoyage.voyage_status_id = 1;
                            parent.filteredVoyages.forEach(fv => fv.disabled = true);
                            parent.filteredVoyages.push(newVoyage);
                            parent.selectedItinerary = response.data.id;
                            parent.manualChosen = true;
                        }
                        parent.customVoyage = false;
                        this.$toast.success("Voyage added");
                        this.submitted = false;
                    }).catch(e => {
                        this.submitted = false;
                        this.toastError(e);
                    });
                }
            },
            checkData(){
                if(this.dataItemsLoaded >= this.dataItemsToLoad){
                    if(this.isEditing){
                        this.setData(false);
                    }
                    else{
                        this.reset();
                    }
                }
            },
            loadData(){
                this.getCarriers();
                this.getOffices();
                this.getPorts();
                this.getLegacyservices();
                this.voyageStatusses = dataStore.getters.getVoyageStatuses;
                this.region_types = dataStore.getters.getRegionTypes;
            }
        }
    }
</script>

<style scoped>
#confirm-dialog{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    max-width: 60rem;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    outline: 0
}
</style>