<template>
    <ul class="nav nav-tabs mb-3" id="crmTab" role="tablist">
        <li class="nav-item" role="presentation">
            <button class="nav-link active" id="typename-tab" data-bs-toggle="tab" data-bs-target="#typename" type="button" role="tab" aria-controls="typename" aria-selected="true" @click="setNextStep">{{'1 - ' + $t('overview.type') + ' & ' + $t('overview.name')}}</button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="roles-tab" data-bs-toggle="tab" data-bs-target="#roles" type="button" role="tab" aria-controls="roles" @click="setNextStep" :disabled="disableFor(2)">{{'2 - ' + $tc('overview.location', 2) + ' & ' + $tc('form.roles', 2)}}</button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="contactinfo-tab" data-bs-toggle="tab" data-bs-target="#contactinfo" type="button" role="tab" aria-controls="contactinfo" @click="setNextStep" :disabled="disableFor(3)">{{'3 - ' + (relation.has_office ? $tc('navigation.users', 2) : $tc('crm.contact_persons', 2))}}</button>
        </li>
        <li class="nav-item" role="presentation" v-if="relation.has_carrier">
            <button class="nav-link" id="ports-tab" data-bs-toggle="tab" data-bs-target="#ports" type="button" role="tab" aria-controls="ports" @click="setNextStep" :disabled="disableFor(4)">{{getTabNumber(4) + ' - ' + $t('navigation.carriers')}}</button>
        </li>
        <li class="nav-item" role="presentation" v-if="relation.has_customer">
            <button class="nav-link" id="customer-tab" data-bs-toggle="tab" data-bs-target="#customers" type="button" role="tab" aria-controls="customers" @click="setNextStep" :disabled="disableFor(4)">{{getTabNumber(5) + ' - ' + $t('navigation.customers')}}</button>
        </li>
        <li class="nav-item" role="presentation" v-if="relation.is_port_representative">
            <button class="nav-link" id="portrep-tab" data-bs-toggle="tab" data-bs-target="#portreps" type="button" role="tab" aria-controls="portreps" @click="setNextStep" :disabled="disableFor(4)">{{getTabNumber(6) + ' - ' + $t('overview.representative')}}</button>
        </li>
        <li class="nav-item" role="presentation" v-if="relation.has_terminal">
            <button class="nav-link" id="terminal-tab" data-bs-toggle="tab" data-bs-target="#terminals" type="button" role="tab" aria-controls="terminals" @click="setNextStep" :disabled="disableFor(4)">{{getTabNumber(7) + ' - ' + $tc('navigation.terminals', 1)}}</button>
        </li>
        <li class="nav-item" role="presentation" v-if="relation.has_office">
            <button class="nav-link" id="nmtcompany-tab" data-bs-toggle="tab" data-bs-target="#nmtcompany" type="button" role="tab" aria-controls="nmtcompany" @click="setNextStep" :disabled="disableFor(4)">{{getTabNumber(8) + ' - ' + $tc('crm.company', 1)}}</button>
        </li>
        
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="tariffs-tab" data-bs-toggle="tab" data-bs-target="#tariffs" type="button" role="tab" aria-controls="tariffs" @click="setNextStep" :disabled="disableFor(4)">{{getTabNumber(9) + ' - ' + $t('navigation.tariffs')}}</button>
        </li>
    </ul>
    <div class="tab-content" id="crmTabContent">

    <!-- Begin Type & Name tab -->
    <div class="tab-pane fade show active" id="typename" role="tabpanel" aria-labelledby="typename-tab">
    <template  v-if="formActive">
        <h4 class="mt-2">{{$tc('navigation.relations', 1)}}</h4> 
        <FormItem id="reltype" :label="$t('overview.type')" v-model="relation.type" type="buttongroup" sizeItem="4" :options="companyOrPerson" @oninput="setNextButtonDisabled(2)" />
        <template v-if='relation.type == "company"'>
            <FormItem id="name" :label="$t('crm.company_name')" v-model="relation.name" sizeItem="4" :required="true" @onchange="checkRelationName" @oninput="setNextButtonDisabled(2)" />
            <div v-show="hits.length" class="row w-50">
                <div class="col-sm-5 pt-0"> </div>
                <div class="col col-form-label-sm pt-0"> {{$t('crm.did_you_mean')}}: </div>
            </div>
            <div v-for="(hit, index) in hits" :key="index" class="row w-50">
                <div class="col-sm-5 pt-0"> </div>
                <div class="col col-form-label-sm pt-0"> <a class="hoverable clickable" @click="selectHit(hit)">{{hit.name}}</a> </div>
            </div>
            <FormItem id="relation_number" :label="$t('crm.registration_id')" v-model="relation.relation_number" sizeItem="4" v-if="!(this.relation.country && this.relation.country.registration_id_same_as_vat_id)" />
            <div class="row mb-2">
                <div class="col-form-label col-form-label-sm" :style="{width: (fromQuote ? '17.6%' : '21%')}">{{ $t('accounting.vat_id') + ' ' + ((this.relation.country && this.relation.country.registration_id_same_as_vat_id) ? '*' : '') }}</div>
                <input id="vatid" v-model="relation.vat" @change="verifyVAT" class="form-control form-control-sm" style="width: 31.5%" :required="this.relation.country && this.relation.country.registration_id_same_as_vat_id" />
                <div v-show="relation.duplicate" class="text-danger col-auto pt-1"><i class="fa fa-warning"></i>&ensp;{{$t('errors.duplicate_in_db', [$t('accounting.vat_id'), relation.duplicate])}}</div>
            </div>
            <FormItem id="country_name" :label="$t('navigation.countries')" v-model="relation.country_id" type="livesearch" sizeItem="4" :options="countries" @onchange="onChangeCountry()"
                     :content="relation.country ? relation.country.name : ''" />
        </template>
        <template v-if='relation.type == "person"'>
            <FormItem id="name" :label="$t('crm.company_name')" v-model="relation.name" sizeItem="4" :required="true" @onchange="checkRelationName" @oninput="setNextButtonDisabled(2)" />
            <div v-show="hits.length" class="row w-50">
                <div class="col-sm-5 pt-0"> </div>
                <div class="col col-form-label-sm pt-0"> {{$t('crm.did_you_mean')}}: </div>
            </div>
            <div v-for="(hit, index) in hits" :key="index" class="row w-50">
                <div class="col-sm-5 pt-0"> </div>
                <div class="col col-form-label-sm pt-0"> <a class="hoverable clickable" @click="selectHit(hit)">{{hit.name}}</a> </div>
            </div>
        </template>
        <div v-if='isEditing && !isProduction' class="row">
            <div class="col-2 me-5 col-form-label col-form-label-sm">{{ $t('navigation.world_check') + (relation.worldcheck_case ? (' - ' + $t('overview.status')) : '') }}</div>
            <div class="col-4 ps-1">
                <span class="badge" :data-status="relation.worldcheck_case.status" v-if="relation.worldcheck_case">
                    {{ $t('quote_status.' + relation.worldcheck_case.status) }}
                </span>
                <button v-else type="button" class="btn btn-primary btn-sm" @click="createCase" :disabled="obtainingWorldCheckInfo">
                    <i class="fa fa-plus"></i>&ensp;{{obtainingWorldCheckInfo ? $t('form.please_wait') : $t('form.add', [$t('navigation.world_check')])}}
                </button>
            </div>
        </div>
        <div class="row" v-if="!fromQuote">
            <div class="col-2 me-5 col-form-label col-form-label-sm">{{ $t('crm.blacklisted') }}</div>
            <div class="col-4 ps-1"><FormItem id="blacklisted" v-model="relation.blacklisted" type="checkbox" /> </div>
        </div>
    </template>
    </div>
    <!--end tab --> 
    <!--Begin Addresses & Roles tab --> 
    <div class="tab-pane fade" id="roles" role="tabpanel" aria-labelledby="roles-tab">
        <h4 class="mt-2">{{$tc('overview.location', 2)}}</h4>
        <div v-for="(address, index) in relation.addresses" :key="index">
            <hr v-show="index > 0">
            <div class="row" v-if="relation.addresses.length > 1">
                <label :for="'br-'+index" class="col-sm-4 col-form-label-sm">{{$tc('form.branch', 1)}}</label>
                <div class="col-sm-4">
                    <FormItem :id="'br-'+index" v-model="address.branch" placeholder="DEFAULT" />
                </div>
                <div class="col-sm-3 hq-checkmark" v-show="relation.addresses.length > 1">
                    <FormItem type="checkbox" :id="'hq-'+index" v-model="address.is_hq" :label="$t('crm.is_hq')" @click="setDefault($event, 'hq-checkmark')" />
                </div>
                <div class="col-sm-1">
                    <a class="btn btn-white pt-1" @click="deleteItem('addresses', index); setNextButtonDisabled(2)"><i class="fa fa-trash-alt text-danger"></i></a>
                </div>
            </div>
            <FormItem :id="'st1-'+index" v-model="address.street" :label="$t('form.address')" type="text" sizeItem="4" @onchange="setNextButtonDisabled(3);" :required="address.po_box == null || !address.po_box.length" :disabled="address.po_box !== null && address.po_box.length > 0" />
            <FormItem :id="'st2-'+index" v-model="address.street_additional" :label="$t('form.address_additional')" type="text" sizeItem="4" :disabled="address.po_box !== null && address.po_box.length > 0" />
            <FormItem :id="'po-'+index" v-model="address.po_box" :label="$t('form.po_box')" type="text" sizeItem="4"  @onchange="setNextButtonDisabled(3);" :required="address.street == null || !address.street.length" :disabled="address.street !== null && address.street.length > 0" /> 
            <FormItem :id="'pc-'+index" v-model="address.postal_code" :label="$t('form.postal_code')" type="text" sizeItem="4"  @onchange="setNextButtonDisabled(3);" :required="true"/>
            <FormItem :id="'ci-'+index" v-model="address.city" :label="$t('form.city')" type="text" sizeItem="4"  @onchange="setNextButtonDisabled(3);" :required="true" />
            <FormItem :id="'co-'+index" :label="$t('navigation.countries')" v-model="address.country_id" type="livesearch" sizeItem="4" :options="countries" 
                      :content="fillSearch(countries, address.country_id)" :required="true" @onchange="setNextButtonDisabled(3); onChangeCountry(index)" />
            <FormItem v-if="!phoneRefresh || address.phone.length > 4" :id="'ph-'+index" v-model="address.phone" :label="$t('form.phone')" type="phone" sizeItem="4" 
                      :phoneDefaultCountry="address.country ? address.country.iso : defaultCountry.iso" />
            <FormItem :id="'em-'+index" v-model="address.email" :label="$t('form.email')" type="email" sizeItem="4" />
        </div>
        <div class="row mt-4 justify-content-center">
            <button class="btn btn-sm btn-primary w-25" title="Click to add item" type="button" @click="addAddress()">
                <span class="fa fa-plus"></span> &nbsp;{{$t('form.add_another', [$tc('navigation.addresses', 1)])}}
            </button>
        </div>
        <h4 class="mt-2">{{$tc('form.roles', 2)}}</h4>
        <div class="row" id="roles-checkboxes">
            <div class="col-sm-4 d-flex flex-column">
                <FormItem id="has_office" v-model="relation.has_office" :label="$t('crm.company')" type="checkbox" @onclick="setNextButtonDisabled(3)" :disabled="relation.type == 'person'" />
                <FormItem id="has_customer" v-model="relation.has_customer" :label="$t('navigation.customers')" type="checkbox" @onclick="getTariffs($event, 4); setNextButtonDisabled(3)" :disabled="fromQuote" />
                <FormItem id="has_supplier" v-model="relation.has_supplier" :label="$t('navigation.vendors')" type="checkbox" @onclick="setNextButtonDisabled(3)" />
            </div>
             <div class="col-sm-4 d-flex flex-column">
                <FormItem id="has_carrier" v-model="relation.has_carrier" :label="$t('navigation.carriers')" type="checkbox" @onclick="getTariffs($event, 2); setNextButtonDisabled(3)" />
                <FormItem id="has_vessel_owner" v-model="relation.has_vessel_owner" label="Vessel owner" type="checkbox" @onclick="setNextButtonDisabled(3)" />
                <FormItem id="is_manufacturer" v-model="relation.is_manufacturer" :label="$t('crm.manufacturer')" type="checkbox" @onclick="setNextButtonDisabled(3)" />
            </div>
            <div class="col-sm-4 d-flex flex-column">
                <FormItem id="has_terminal" v-model="relation.has_terminal" :label="$t('navigation.terminals')" type="checkbox" @onclick="getTariffs($event, 1); setNextButtonDisabled(3)" />
                <FormItem id="is_port_representative" v-model="relation.is_port_representative" :label="$t('overview.representative')" type="checkbox" @onclick="setNextButtonDisabled(3)" />
                <FormItem id="has_surveyor" v-model="relation.has_surveyor" :label="$t('overview.surveyer')" type="checkbox" @onclick="setNextButtonDisabled(3)" />
            </div>
        </div>
    </div>
    <!--end tab --> 
    
    <!--Begin customers tab --> 
    <div class="tab-pane fade" id="customers" role="tabpanel" aria-labelledby="customers-tab" v-if="relation.has_customer">
        <h5 class="mt-3">{{$t('navigation.customers') + ' - ' + $t('navigation.general_details')}}</h5>
        <FormItem id="eori" v-model="relation.eori" label="Eori" sizeItem="4" />
        <h5 class="mt-4 mb-3">{{$t('navigation.customers') + ' - ' + $t('navigation.bills_of_lading')}}</h5>
        <FormItem id="has_shipper" v-model="relation.customer.use_default_shipper" :label="$t('crm.has_shipper')" type="checkbox" /> 
        <div class="shipper-details" v-if="relation.customer.use_default_shipper">
            <FormItem id="shipper-1" v-model="relation.customer.shipper_address.information" :label="$t('overview.name')" sizeItem="4" :required="true" />
            <FormItem id="shipper-2" v-model="relation.customer.shipper_address.street" :label="$t('navigation.addresses')" sizeItem="4" type="textarea" class="textarea-uppercase" />
            <FormItem id="shipper-3" :label="$t('navigation.countries')" v-model="relation.customer.shipper_address.country_id" type="livesearch" sizeItem="4" :options="countries" 
                        :content="fillSearch(countries, relation.customer.shipper_address.country_id)" :required="true" />
            <FormItem id="shipper-4" v-if="!phoneRefresh" v-model="relation.customer.shipper_address.phone" :label="$t('form.phone')" sizeItem="4" type="phone" :phoneDefaultCountry="defaultCountry.iso" />
            <FormItem id="shipper-5" v-model="relation.customer.shipper_address.email" :label="$t('form.email')" sizeItem="4" type="email" />
        </div>
        <h5 class="mt-4 mb-3">{{$t('navigation.customers') + ' - ' + $t('navigation.invoices')}}</h5>
        <FormItem id="def-curr" v-model="relation.customer.currency_id" :options="currencies" :label="$t('form.default', [$t('navigation.currencies')])"
                    type="select" sizeItem="4" nameField="code" />
        <FormItem id="invoice-1" v-model="relation.customer.single_currency_invoice" :label="$t('crm.only_one_currency')" type="checkbox" />
        <FormItem id="invoice-2" v-model="relation.customer.combined_bookings_invoice" :label="$t('crm.combine_bookings')" type="checkbox" />

        <h5 class="mt-4 mb-3" v-show="relation.customer.customer_offices && relation.customer.customer_offices.length">{{$t('navigation.customers') + ' - ' + $t('crm.debtor_number')}}</h5>
        <div v-for="(customeroffice, index) in relation.customer.customer_offices" :key="index">
            <FormItem :label="customeroffice.office.relation.name" v-model="customeroffice.external_debtor_id" sizeItem="4" />
        </div>

        <h5 class="mt-4 mb-3">{{$t('navigation.customers') + ' - ' + $t('masterdata.finance')}}</h5>
        <FormItem id="cusemail" v-model="relation.customer.email_customer" :label="$t('form.email')" sizeItem="4" />
        <FormItem id="cpnemail" v-if="relation.type == 'company'" v-model="relation.customer.default_contact_person" :label="$t('form.default', [$t('crm.contact_persons')])" sizeItem="4" />
        <FormItem v-model="relation.customer" type="currency-and-amount" :label="$t('crm.credit_limit')" :options="currencies" nameField="code" sizeItem="4" :calcUnitFields="creditLimitFields" />
        <FormItem id="date" type="select" :label="$t('navigation.payment_terms')" v-model="relation.customer.payment_terms_id" :options="paymentTerms" sizeItem="4" />
        <FormItem id="clientnotice" type="textarea" v-model="relation.customer.client_notice" :label="$t('crm.client_notice')" sizeItem="4" />
    </div>
    <!--end tab --> 
    
    <!--Begin port rep tabs --> 
    <div class="tab-pane fade" id="portreps" role="tabpanel" aria-labelledby="portreps-tab" v-if="relation.is_port_representative">
        <h5 class="mt-3">{{$t('overview.representative')}}</h5>
        <FormItem id="repv1" :label="$t('crm.group_label')" v-model="relation.representative.group_bls_zip" type="buttongroup" sizeItem="4" :options="groupBLOptions" />
        <FormItem id="repv2" :label="$t('crm.manf_label')" v-model="relation.representative.manifest_type_measurement" type="buttongroup" sizeItem="4" :options="manifestTypeOptions" />
        <FormItem id="repv3" type="number-with-tags" v-model="relation.representative.commision" :label="$t('crm.commission')" sizeItem="2" endTag="%" />
        <FormItem id="trepv" :label="$t('navigation.ports', 2)" v-model="relation.representative.ports" type="livesearch-list" sizeItem="4" :options="ports" :content="relation.representative.ports.name" />
        <FormItem id="repv4" :label="$t('crm.cfr_emails')" v-model="relation.representative.cfr_emails" type="textarea" options="4" sizeItem="4" />
        <div class="row">
            <div class="col-2 me-5 col-form-label col-form-label-sm">{{ $t('crm.cfr_copy_bl') }}</div>
            <div class="col-4 ps-1"><FormItem id="prev5" v-model="relation.representative.cfr_copy_bl" type="checkbox" /> </div>
        </div>
    </div>
    <!--end tab --> 
    
    <!--Begin terminal tabs --> 
    <div class="tab-pane fade" id="terminals" role="tabpanel" aria-labelledby="terminals-tab" v-if="relation.has_terminal">
        <h5 class="mt-3">{{$tc('navigation.terminals', 1)}}</h5>
        <FormItem id="tport" :label="$t('navigation.ports')" v-model="relation.terminal.port_id" type="livesearch" sizeItem="4" :options="ports" 
                    :content="relation.terminal && relation.terminal.port ? relation.terminal.port.name : ''" />
        
        <FormItem id="tinfo" v-model="relation.terminal.information" :label="$t('crm.information')" sizeItem="4" type="textarea" />
        <FormItem id="tnotes" v-model="relation.terminal.notes" :label="$t('crm.delivery_info')" sizeItem="4" type="textarea" />
    </div>
    <!--end tab -->

    <!--Begin office tabs --> 
    <div class="tab-pane fade" id="nmtcompany" role="tabpanel" aria-labelledby="nmtcompany-tab" v-if="relation.has_office">
        <h5 class="mt-3">{{$tc('crm.company', 1)}}</h5>
        <FormItem id="managerenmail" v-model="relation.office.manager_email" :label="$t('crm.email')" sizeLabel="3" sizeItem="4" type="email" />
    </div>
    <!--end tab --> 

    <!--Begin tariffs tab --> 
    <div class="tab-pane fade" id="tariffs" role="tabpanel" aria-labelledby="tariffs-tab">
        <h4 class="mt-2">{{$tc('navigation.tariffs', 2)}}</h4>
        <template v-if="relation.has_carrier && (relation.carrier.relation_general_cost || relation.carrier.relation_general_cost_commodities)">
            <RelationFormSubcomponent :relationItem="relation.carrier" :currencies="currencies" :calculation_units="calculation_units" type="carrier" />
        </template>
        <template v-if="relation.has_terminal && (relation.terminal.relation_general_cost || relation.terminal.relation_general_cost_commodities)">
            <RelationFormSubcomponent :relationItem="relation.terminal" :currencies="currencies" :calculation_units="calculation_units" type="terminal" />
        </template>
        <template v-if="relation.has_customer && (relation.customer.relation_general_cost || relation.customer.relation_general_cost_commodities)">
            <RelationFormSubcomponent :relationItem="relation.customer" :currencies="currencies" :calculation_units="calculation_units" type="customer" />
        </template>
        <p v-show="!hasGeneralCosts()">
            {{$t('form.no_available', [$tc('navigation.tariffs', 2)])}}
        </p>
    </div>
    <!--end tab --> 
    <!--Begin Carrier tab --> 
    <div class="tab-pane fade" id="ports" role="tabpanel" aria-labelledby="ports-tab" v-if="relation.has_carrier">
        <h4 class="mt-3">{{$tc('navigation.carriers', 1)}}</h4>
        <FormItem id="preferred" v-model="relation.carrier.preferred" :label="$t('overview.preferred_carrier')" type="checkbox" /> 
        <div class="row mt-1">
            <label for="logo" class="col-sm-2 form-check-label-sm">{{$t('overview.logo')}}</label>
            <div class="col-sm-4">
                <img v-if="relation.carrier.logo" :src="relation.carrier.logo" style="max-width: 300px">
                <FormItem id="logo" @onchange="handleFileChange" type="file" /> 
                <a v-show="relation.carrier.logo" class="btn btn-white" @click="deleteLogo"><i class="fa fa-trash-alt text-danger"></i></a>
            </div>
        </div>
        <hr/>
        <h5 class="mt-2">{{$t('crm.transshipment_ports')}}</h5>
        <p v-show="relation.carrier.carrier_from_ports.length == 0">{{$t('form.no_yet', [$tc('navigation.ports', 2)])}}</p>
        <template v-for="cport, cindex in relation.carrier.carrier_from_ports" :key="cindex">
            <hr v-show="cindex > 0" />
            <div class="row my-2">
                <div class="col-6 trans-from">
                    <label class="pt-1">{{$t('crm.transshipment_from')}}</label>
                    <FormItem :id="'from-'+cindex" v-model="cport.port_id" type="livesearch" :options="ports" :content="fillSearch(ports, cport.port_id)" :required="true" />
                </div>
                <div class="col-6 trans-to">
                    <div class="w-100 d-flex justify-content-between">
                        <label class="pt-1">{{$t('crm.transshipment_to')}}</label>
                        <a class="btn btn-white" @click="deleteItem('port', cindex)"><i class="fa fa-trash-alt text-danger"></i></a>
                    </div>
                    <FormItem :id="'to-'+cindex" v-model="cport.carrier_to_ports" type="livesearch-list" :options="ports" :content="cport.carrier_to_ports.name" />
                </div>
            </div>
        </template>
        <div class="row mt-4 justify-content-center">
            <button class="btn btn-sm btn-primary w-25" title="Click to add item" type="button"  @click="addTransshipmentPort()">
                <span class="fa fa-plus"></span> &nbsp;{{$t('form.add', [$tc('navigation.ports', 1)])}}
            </button>
        </div>
    </div>
    <!--end tab --> 
    <!--Begin Contact persons tab --> 
    <div class="tab-pane fade" id="contactinfo" role="tabpanel" aria-labelledby="contactinfo-tab">
        <template v-if="relation.has_office">
            <h4 class="mt-2">{{$tc('navigation.users', 2)}}</h4>
            <p v-show="!relation.office || relation.office.users.length == 0">{{ $t('form.no_found', [$tc('navigation.users', 2)]) }}</p>
            <table class="p-datatable-table" v-if="relation.office && relation.office.users.length > 0">
                <thead class="p-datatable-thead">
                    <th class="p-2" style="width: 150px">{{ $t('overview.photo') }}</th>
                    <th class="p-2">{{ $t('overview.name') }}</th>
                    <th class="p-2">{{ $t('form.email') }}</th>
                    <th class="p-2">{{ $t('overview.location') }}</th>
                    <th class="p-2" style="width: 150px">{{ $t('overview.external_user') }}</th>
                </thead>
                <tbody class="p-datatable-tbody">
                    <tr v-for="user in relation.office.users" :key="user.name">
                        <td class="cell">
                            <template v-if="user.profile_image">
                                <img :src="user.profile_image" alt="" width="40" height="40" class="rounded-circle me-2" />
                            </template>
                        </td>
                        <td role="cell">{{ user.name }}</td>
                        <td role="cell">{{ user.email }}</td>
                        <td role="cell">{{ user.location }}</td>
                        <td role="cell"><i v-if="user.external" class="fas fa-check text-success" /></td>
                    </tr>
                </tbody>
            </table>
        </template>
        <template v-else>
            <h4 class="mt-2">{{$tc('crm.contact_persons', 2)}}</h4>
            <div v-for="(contact, index) in relation.contacts" :key="index">
                <hr v-show="index > 0">
                <div class="row">
                    <label :for="'zfn-'+index" class="col-sm-4 col-form-label-sm required-label">{{$t('form.first_name')}}</label>
                    <div class="col-sm-4">
                        <FormItem :id="'zfn-'+index" v-model="contact.first_name" sizeItem="4" :required="true" @oninput="setNextButtonDisabled(4)" />
                    </div>
                    <div class="col-sm-2">
                        <a class="btn btn-white pt-1" @click="deleteItem('contacts', index); setNextButtonDisabled(4)"><i class="fa fa-trash-alt text-danger"></i></a>
                    </div>
                </div>
                <FormItem :id="'zln-'+index" :label="$t('form.last_name')" v-model="contact.last_name" sizeItem="4" :required="true" @oninput="setNextButtonDisabled(4)" />
                <FormItem :id="'zem-'+index" :label="$t('form.email')" v-model="contact.email" sizeItem="4" type="email" :required="true" @oninput="setNextButtonDisabled(4)" />
                <FormItem :id="'zph-'+index" v-if="!phoneRefresh" :label="$t('form.phone')" v-model="contact.phone" sizeItem="4" type="phone" :phoneDefaultCountry="defaultCountry.iso" />
                <FormItem :id="'zmob-'+index" v-if="!phoneRefresh" :label="$t('form.mobile')" v-model="contact.mobile" sizeItem="4" type="phone" :phoneDefaultCountry="defaultCountry.iso" />
                <FormItem :id="'zoff-'+index" v-if="isEditing" :label="$t('overview.location')" v-model="contact.address_id" type="select" idField="rand_id" nameField="tempname" 
                          :options="setAddressLocations()" sizeItem="4" />
            </div>
            <div class="row mt-4 justify-content-center">
                <button class="btn btn-sm btn-primary w-25" title="Click to add item" type="button"  @click="addContactPerson()">
                    <span class="fa fa-plus"></span> &nbsp;{{$t('form.add_another', [$tc('crm.contact_persons', 1)])}}
                </button>
            </div>
        </template>
    </div>
    <!--end tab --> 
    </div>
</template>

<script>
    import costservice from '@/services/GeneralcostService'
    import relationservice from '@/services/RelationService'
    import representativeService from '@/services/RepresentativeService'
    import currencyservice from '@/services/CurrencyService'
    import calculationunitservice from '@/services/CalculationunitService'
    import portService from "@/services/PortService";
    import countryService from "@/services/CountryService";
    import paymentTermService from '@/services/PaymentTermService';
    import RelationFormSubcomponent from '@/components/relation/RelationFormSubcomponent.vue'
    import dataStore from '@/store/data'
    import 'vue-tel-input/dist/vue-tel-input.css';
    export default {
        name: 'RelationListComponent',
        props: ['itemData', 'fromQuote'],
        components: {
            RelationFormSubcomponent
        },
        data() {
            return {
                relation: { addresses: [], contacts: [], customer: { booking: {} }, carrier: { carrier_from_ports: [] }, representative: { ports: [] }, terminal: {}, office: {} },
                defaultCountry: {id: null, name: '', iso: 'NL'},
                relationtypes: [],
                companyOrPerson: [{id: 'company', name: this.$t('crm.company_type')},{id: 'person', name: this.$t('crm.person')}],
                groupBLOptions: [{id: false, name: this.$t('crm.group_opt_1')},{id: true, name: this.$t('crm.group_opt_2')}],
                manifestTypeOptions: [{id: false, name: this.$t('crm.manf_opt_1')},{id: true, name: this.$t('crm.manf_opt_2')}],
                phoneRefresh: false,
                submitted: false,
                loading: false,
                invalid: {},
                countries: [],
                generalCosts: [],
                currencies: [],
                calculation_units: [],
                ports: [],
                hits: [],
                paymentTerms: [{id: 7, name: 'Unknown'}],
                creditLimitFields: ['credit_limit_amount', 'credit_limit_currency_id'],
                canGoToStepTwo: false,
                canGoToStepThree: false,
                canGoToStepFour: false,
                obtainingWorldCheckInfo: false,
                dataItemsLoaded: 0,
                dataItemsToLoad: 4,
                nextButtonId: 'nextbutton',
                saveButtonId: 'savebutton',
                representativeService: representativeService,
                isEditing: false,
                formActive: false
            }
        },
        watch: {
            itemData: function (val) {
                this.relation = val    
            },
            'relation.has_carrier': function (newVal){
                if(newVal && !this.relation.carrier) {
                    this.relation.carrier = {
                        'preferred':0,
                        'logo': ''
                    }
                }
            }
        },
        computed: {
            isProduction() {
                return process.env.VUE_APP_IS_PRODUCTION == "true";
            }
        },
        methods: {
            active(value){
                this.formActive = value;
            },
            validate(){
                let r = this.relation;

                if(this.relation.type == null){
                    alert("Please specify the type of relation.");
                    this.setNextStep(1);
                    return false;
                }
                if(!r.has_vessel_owner && !r.has_carrier && !r.has_terminal && !r.has_customer && !r.has_office && !r.has_supplier && !r.is_manufacturer && !r.has_surveyor && !r.is_port_representative){
                    alert("Please select at least one role.");
                    this.setNextStep(2);
                    return false;
                }

                let tabs = ["typename", "roles", "contact", "details"];
                let valid = true;
                let step = 1;
                tabs.forEach(tab => {
                    if(!valid) return;
                    valid = this.checkFields(tab);
                    if(valid) step++;
                });
                if(!valid){
                    alert("Not all required fields are filled in correctly.");
                    this.setNextStep(step);
                    return false;
                }
                this.relation.carrier.carrier_from_ports.forEach((port, pIndex) => {
                    if(port.port_id == null){
                        valid = false;
                        document.querySelector('#from-'+pIndex+'_wrapper input').classList.add('is-invalid');
                    }
                    if(port.carrier_to_ports.length == 0){
                        valid = false;
                        document.querySelector('#to-'+pIndex+'_wrapper input').classList.add('is-invalid');
                    }
                });
                if(!valid){
                    alert("Not all ports are filled in.");
                    return false;
                }
                else{
                    this.relation.has_shipper = this.relation.customer.use_default_shipper;
                    if(this.relation.office && this.relation.office.users){
                        this.relation.office.users = [];
                    }
                }
                return true;
            },
            getData() {
                return this.relation;
            },
            setData(data) {
                this.isEditing = true;
                this.canGoToStepTwo = true;
                this.canGoToStepThree = true;
                this.canGoToStepFour = true;
                document.getElementById('nextbutton').disabled = false;
                if(data) {
                    this.relation = data;
                    console.log(this.relation);
                    /*this.relation.addresses.forEach(add => {
                        if(add.postal_code && add.city){
                            add.street += ("\n" + add.postal_code + " " + add.city);
                            add.postal_code = null;
                            add.city = null;
                        }
                    });*/
                    if(!this.relation.customer) {
                        this.relation.customer = {}
                    }
                    if(!this.relation.carrier){
                        this.relation.carrier =  {
                            'preferred': 0,
                            'logo': '',
                            "carrier_from_ports": []
                        };
                    }
                    if(!this.relation.representative){
                        this.relation.representative = {
                            'group_bls_zip': false,
                            'manifest_type_measurement': false,
                            "commision": null,
                            "ports": [],
                            "cfr_emails": "",
                            "cfr_copy_bl": 0
                        };
                    }
                    if(!this.relation.office){
                        this.relation.office = {
                            'manager_email': ''
                        };
                    }
                    this.relation.addresses.forEach(add => add.rand_id = add.id);
                    if(this.relation.customer.credit_limit_currency_id == null){
                        this.relation.customer.credit_limit_currency_id = window.defaultCurrency;
                    }
                    if(this.relation.customer.shipper_address == null){
                        this.relation.customer.shipper_address = {
                            "information": "",
                            "email": "",
                            "phone": "",
                            "street": "",
                            "house_number": "",
                            "suffix": "",
                            "postal_code": "",
                            "city": "",
                            "country_id": null
                        };
                        this.relation.has_shipper = 0;
                    }
                    else{
                        this.relation.has_shipper = true;
                    }
                    this.relation.customer.booking = {};
                    this.relation.phone = this.relation.phone == null ? '' : this.relation.phone;
                    if(this.relation.country_id || this.relation.addresses[0]){
                        this.defaultCountry = this.relation.country_id ? this.relation.country : this.relation.addresses[0].country;
                    }
                    if(this.relation.terminal && !this.relation.terminal.information && this.relation.terminal.notes && this.relation.terminal.notes.includes("pening")){
                        this.relation.terminal.information = this.relation.terminal.notes;
                        this.relation.terminal.notes = null;
                    }
                    this.loadData();
                }
                if(this.dataItemsLoaded < this.dataItemsToLoad) return;

                this.isEditing = true;
                this.relation.addresses.forEach(address => {
                    address.phone = address.phone == null ? '' : address.phone;
                    address.mobile = address.mobile == null ? '' : address.mobile;
                    // if(address.street && address.street.length > 0) address.po_box = '';
                    // if(address.po_box && address.po_box.length > 0) address.street = '';
                });
                if(!this.relation.relation_number && this.relation.country && this.relation.country.registration_id_same_as_vat_id){
                    const el = document.getElementById(this.relation.country ? "vatid" : "relation_number");
                    el.classList.add("is-invalid");
                }
                if(this.relation.addresses[0] && !this.relation.addresses[0].is_hq){
                    this.relation.addresses[0].is_hq = true;
                }
                this.getTariffsOnSetData(this.relation);
                if(this.relation.has_carrier){
                    this.getTariffs(true, 2);
                }
                if(this.relation.has_customer){
                    this.getTariffs(true, 4);
                }
                if(this.relation.has_terminal){
                    this.getTariffs(true, 1);
                }
                
                this.canGoToStepTwo = true;
                this.canGoToStepThree = true;
                this.canGoToStepFour = true;
                document.getElementById('nextbutton').disabled = false;
                this.dataItemsLoaded = 0;
            },
            reset(isEditing = false) {
                this.canGoToStepTwo = false;
                this.canGoToStepThree = false;
                this.canGoToStepFour = false;
                this.defaultCountry = {id: null, name: '', iso: 'NL'};
                this.hits = [];
                this.$nextTick(() => document.getElementById('typename-tab').click());
                if(this.dataItemsLoaded < this.dataItemsToLoad || !isEditing){
                    this.relation = {
                        'name': null,
                        'type': null,
                        'code': null,
                        'eori': null,
                        'country_id': null,
                        'blacklisted': 0,
                        'has_vessel_owner': 0,
                        'has_carrier': 0,
                        'has_terminal': 0,
                        'has_customer': 0,
                        'has_shipper': 0,
                        'has_office': 0,
                        'has_supplier': 0,
                        'is_port_representative': 0,
                        'is_manufacturer': 0,
                        'phone': '',
                        'mobile': '',
                        addresses: [],
                        contacts: [],
                        customer: {
                            'shipper_address': {
                                "information": "",
                                "email": "",
                                "phone": "",
                                "street": "",
                                "house_number": "",
                                "suffix": "",
                                "postal_code": "",
                                "city": "",
                                "country_id": null
                            },
                            'use_default_shipper': 0,
                            'currency_id': null,
                            'single_currency_invoice': 0,
                            'combined_bookings_invoice': 0,
                            'payment_terms_id': 7,
                            'email_customer': null,
                            'default_contact_person': null,
                            'credit_limit_amount': '', 
                            'credit_limit_currency_id': null
                        },
                        carrier: {
                            'preferred': 0,
                            'logo': '',
                            "carrier_from_ports": []
                        },
                        representative: {
                            'group_bls_zip': false,
                            'manifest_type_measurement': false,
                            "commision": null,
                            "ports": [],
                            "cfr_emails": "",
                            "cfr_copy_bl": 0
                        },
                        terminal: {
                            'port_id': null,
                            'notes': null,
                            'information': null
                        },
                        office: { 
                            'manager_email': '',
                            'users': []
                        }
                    }
                }
                
                if(this.fromQuote){
                    this.relation.has_customer = 1;
                    this.nextButtonId = 'innernextbutton';
                    this.saveButtonId = 'innersavebutton';
                    this.isEditing = false;
                }
                if(this.dataItemsLoaded < this.dataItemsToLoad){
                    this.isEditing = isEditing;
                    if(!this.isEditing) this.loadData();
                    return;
                }
                this.relation.customer.credit_limit_currency_id = window.defaultCurrency;
                if(!this.isEditing){
                    this.addAddress()
                    this.addContactPerson()
                }
                this.loading = false
                this.invalid = {}
                this.dataItemsLoaded = 0
            },
            
            getTariffs(e, id){
                if(id == 4) this.setCustomer();
                const checked = e === true ? e : e.target.checked;
                let field = this.relationtypes.find(type => type.id == id).name;
                if(!this.relation[field]) this.relation[field] = {};
                //Add elements
                if(checked){
                    this.generalCosts.forEach(cost => {
                        if(cost.managed_by_relations && cost.relation_type_id == id){
                            let newCost = cost;
                            newCost.general_cost_id = cost.id;
                            if(cost.commodity_dependent){
                                if(this.relation[field].relation_general_cost_commodities === undefined){
                                    this.relation[field].relation_general_cost_commodities = [];
                                }
                                //Don't add the same general cost twice
                                if(this.relation[field].relation_general_cost_commodities.find(gencost => gencost.general_cost_id == newCost.general_cost_id)) return;
                                //Add the cost
                                this.relation[field].relation_general_cost_commodities.push(newCost);
                            }
                            else{
                                if(this.relation[field].relation_general_cost === undefined){
                                    this.relation[field].relation_general_cost = [];
                                }
                                //Don't add the same general cost twice
                                if(this.relation[field].relation_general_cost.find(gencost => gencost.general_cost_id == newCost.general_cost_id)) return;
                                //Add the cost
                                this.relation[field].relation_general_cost.push(newCost);
                            }
                        }
                    });
                }
                //Remove elements
                else{
                    if(this.relation[field].relation_general_cost){
                        delete this.relation[field].relation_general_cost;
                    }
                    if(this.relation[field].relation_general_cost_commodities){
                        delete this.relation[field].relation_general_cost_commodities;
                    }
                }
            },
            getTariffsOnSetData(data){
                data.general_cost.forEach(cost => {
                    let field = cost.relation_type.name;
                    let newCost = cost;
                    newCost.description = cost.general_cost.description;
                    if(this.relation[field].relation_general_cost === undefined){
                        this.relation[field].relation_general_cost = [];
                    }
                    delete newCost.general_cost;
                    this.relation[field].relation_general_cost.push(newCost);
                });
                data.general_cost_commodity.forEach(cost => {
                    let field = cost.relation_type.name;
                    let newCost = cost;
                    newCost.description = cost.general_cost.description;
                    if(this.relation[field].relation_general_cost_commodities === undefined){
                        this.relation[field].relation_general_cost_commodities = [];
                    }
                    delete newCost.general_cost;
                    
                    const existingIndex = this.relation[field].relation_general_cost_commodities.findIndex(comm => comm.general_cost_id == cost.general_cost_id);
                    if(existingIndex !== -1){
                        this.relation[field].relation_general_cost_commodities[existingIndex].commodities.push(newCost);
                    }
                    else{
                        this.relation[field].relation_general_cost_commodities.push({'commodities': [newCost], 'description': newCost.description, 'general_cost_id': cost.general_cost_id});
                    }
                });
            },
            
            getTabNumber(tabId){
                let maxStep = 4;
                if(this.relation.has_carrier && tabId >= 5) maxStep++;
                if(this.relation.has_customer && tabId >= 6) maxStep++;
                if(this.relation.is_port_representative && tabId >= 7) maxStep++;
                if(this.relation.has_terminal && tabId >= 8) maxStep++;
                if(this.relation.has_office && tabId >= 9) maxStep++;
                return maxStep;
            },
            setCustomer(){
                if(!this.relation.customer.shipper_address){
                    this.relation.customer = {
                        'shipper_address': {
                            "information": "",
                            "email": "",
                            "phone": "",
                            "street": "",
                            "house_number": "",
                            "suffix": "",
                            "postal_code": "",
                            "city": "",
                            "country_id": null
                        },
                        'use_default_shipper': false,
                        'currency_id': null,
                        'single_currency_invoice': false,
                        'combined_bookings_invoice': false,
                        'payment_terms_id': 7,
                        'email_customer': null,
                        'default_contact_person': null,
                        'credit_limit_amount': '', 
                        'credit_limit_currency_id': window.defaultCurrency
                    };
                }
            },
            checkFields(id){
                let fields = document.querySelectorAll('#' + id + ' input, #' + id + ' select');
                let valid = true;
                fields.forEach(el => {
                    el.classList.remove('is-invalid');
                    if(el.getAttribute('type') == 'radio' || el.getAttribute('type') == 'checkbox' || el.getAttribute('type') == 'file')
                        return;
                    if((!el.value && !el.required) || el.classList.contains('is-valid'))
                        return;
                    //For vue-tel-input specifically
                    if(el.classList.contains('vti__input')){
                        if(!el.value) return;
                        if(el.value.length > 9 && el.value.length < 18 && el.value.match(/^\+\d{1,4} [0-9 ]+$/)){
                            el.parentElement.classList.remove('is-invalid');
                            return;
                        }
                        el.parentElement.classList.add('is-invalid');
                        valid = false;
                        return;
                    }
                    else if(el.value && !el.classList.contains('is-invalid') && el.getAttribute('type') != 'tel')
                        return;
                    el.classList.add('is-invalid');
                    valid = false;
                });
                return valid;
            },
            addAddress(){
                let i = this.relation.addresses.push({
                    'street': '',
                    'po_box': '',
                    'house_number': '',
                    'suffix': '',
                    'postal_code': '',
                    'city': '',
                    'country_id': this.defaultCountry.id,
                    'email': '',
                    'phone': '',
                    'branch': '',
                    'is_hq': this.relation.addresses.length == 0,
                    'rand_id': Math.random()
                });
                i -= 1;
                this.$nextTick(() => {
                    let firstaddressCountry = document.querySelector('co-'+i+'_wrapper input');
                    if(firstaddressCountry != null && !firstaddressCountry.value){
                        firstaddressCountry.value = this.defaultCountry.name;
                    }
                });
                this.setNextButtonDisabled(3);
            },
            setDefault(e, className){
                const checkbox = e.target;
                if(checkbox.checked === undefined) return;
                if(checkbox.checked){
                    const els = document.querySelectorAll(`.${className} input`);
                    els.forEach(el => {
                        if(el.id != checkbox.id && el.checked) el.click();
                    });
                }
            },
            addContactPerson(){
                this.relation.contacts.push({
                    'first_name': '',
                    'last_name': '',
                    'email': '',
                    'phone': '',
                    'mobile': '',
                    'address_id': null
                });
                this.setNextButtonDisabled(4);
            },
            addTransshipmentPort(){
                this.relation.carrier.carrier_from_ports.push({
                    "port_id": null,
                    "carrier_to_ports": []
                });
            },
            deleteItem(item, index){
                if(item == 'port'){
                    this.relation.carrier.carrier_from_ports.splice(index, 1);
                }
                else{
                    const relationItem = this.relation[item][index];
                    const name = item == 'addresses' ? (relationItem.street ? (' ' + relationItem.street) : '')
                                                     : (relationItem.first_name ? (' ' + relationItem.first_name) : '');
                    if(confirm(this.$t('form.are_you_sure_delete') + name + '?'))
                        this.relation[item].splice(index, 1);
                }
                if(item == 'addresses' && this.relation.addresses.length == 0){
                    this.addAddress();
                }
            },
            deleteLogo() {
                if(confirm("Are you sure you want to delete this logo?")) this.relation.carrier.logo = '';
            },
            setNextStep(e){
                if(!isNaN(e)){
                    console.log(e);
                    document.querySelectorAll("#crmTab .nav-link")[e - 1].click();
                    return;
                }
                let step = Number(e.target.innerHTML[0]);
                document.getElementById(this.fromQuote ? "innernextstep" : "nextStep").innerHTML = step + 1;
                if(!this.fromQuote){
                    if(this.relation.name)
                        document.getElementById("detailsSpan").innerHTML = ": " + this.relation.name;
                    else
                        document.getElementById("detailsSpan").innerHTML = "";
                }

                let maxStep = 4;
                if(this.relation.has_carrier) maxStep++;
                if(this.relation.has_customer) maxStep++;
                if(this.relation.is_port_representative) maxStep++;
                if(this.relation.has_terminal) maxStep++;

                if(step == maxStep)
                    document.getElementById(this.nextButtonId).classList.add('d-none');
                else
                    document.getElementById(this.nextButtonId).classList.remove('d-none');

                if(step == maxStep || this.isEditing)
                    document.getElementById(this.saveButtonId).classList.remove('d-none');
                else
                    document.getElementById(this.saveButtonId).classList.add('d-none');

                this.setNextButtonDisabled(step == 1 ? 2 : (step + 1));
                if(this.fromQuote){
                    this.$parent.innerformStep = step;
                }
            },
            setNextButtonDisabled(step){
                const r = this.relation;
                this.$nextTick(() => {
                    let validVAT = true;
                    if(r.type == 'company' && r.vat && !this.verifyVAT(null, true)){
                        validVAT = false;
                    }
                    this.canGoToStepTwo = r.type != null && (r.type == 'company' ? (r.name && validVAT) : (r.name));
                    if(step == 4){
                        this.canGoToStepFour = true;
                        if(!r.has_office){
                            r.contacts.forEach(contact => {
                                if(!contact.first_name || !contact.last_name || !contact.email){
                                    this.canGoToStepFour = false;
                                }
                            });
                        }
                    }
                    else if(step == 3 && this.canGoToStepTwo && r.addresses.length > 0){
                        this.canGoToStepThree = true;
                        const match = document.querySelector('#roles-checkboxes input[type="checkbox"]:checked');
                        if(match == null){
                            this.canGoToStepThree = false;
                        }
                        r.addresses.forEach(address => {
                            if(
                                (!address.street && !address.po_box)
                                || !address.postal_code
                                || !address.city
                                || !address.country_id){
                                this.canGoToStepThree = false;
                            }
                        });
                    }
                    let nextButton = document.getElementById(this.nextButtonId);
                    if(step == 2){
                        nextButton.disabled = !this.canGoToStepTwo;
                    }
                    else if(step == 3){
                        nextButton.disabled = !this.canGoToStepThree;
                    }
                    else if(step > 3){
                        nextButton.disabled = !this.canGoToStepFour;
                    }
                });
            },
            disableFor(step){
                if(step == 2) return !this.canGoToStepTwo;
                if(step == 3) return !this.canGoToStepTwo || !this.canGoToStepThree;
                return !this.canGoToStepTwo || !this.canGoToStepThree || !this.canGoToStepFour;
            },
            onChangeCountry(index) {
                const match = this.countries.find(item => item.id == (index === undefined ? this.relation.country_id : this.relation.addresses[index].country_id));
                const name = match ? match.name : '';
                const iso = match ? match.iso : 'NL';
                if(index === undefined){
                    let firstaddressCountry = document.querySelector('#roles .simple-typeahead-input');
                    if(firstaddressCountry != null && !this.relation.addresses[0].street){
                        this.relation.addresses[0].phone = '';
                        this.relation.addresses[0].country_id = this.relation.country_id;
                    }
                    this.defaultCountry = {id: this.relation.country_id, name: name, iso: iso};
                    this.verifyVAT();
                    countryService.show(this.relation.country_id).then(response => {
                        this.relation.country = {
                            id: response.data.id,
                            name: response.data.name,
                            registration_id_same_as_vat_id: response.data.registration_id_same_as_vat_id
                        }
                    }).catch(e => {
                        console.log(e);
                    });
                }
                else{
                    this.relation.addresses[index].phone = '';
                    this.relation.addresses[index].country = {id: this.relation.country_id, name: name, iso: iso};
                }
                this.phoneRefresh = true;
                this.$nextTick(() => this.phoneRefresh = false);
            },
            createCase(){
                this.obtainingWorldCheckInfo = true;
                relationservice.createCase(this.relation.id, this.relation).then(response => {
                    document.querySelector("#modal-close-button").click();
                    window.location.hash = '';
                    this.obtainingWorldCheckInfo = false;
                    this.$parent.$parent.getIndex();
                }).catch(error => {
                    console.log('error', error);
                    this.toastError(error);
                    this.obtainingWorldCheckInfo = false;
                })
            },
            hasGeneralCosts(){
                if(this.relation.has_carrier && (this.relation.carrier.relation_general_cost || this.relation.carrier.relation_general_cost_commodities)) return true;
                if(this.relation.has_customer && (this.relation.customer.relation_general_cost || this.relation.customer.relation_general_cost_commodities)) return true;
                if(this.relation.has_terminal && (this.relation.terminal.relation_general_cost || this.relation.terminal.relation_general_cost_commodities)) return true;
                return false;
            },
            setAddressLocations(){
                let locations = this.relation.addresses.filter(add => add.id != undefined && add.id != null);
                locations.forEach(loc => {
                    loc.tempname = (loc.branch ? loc.branch : loc.street);
                });
                return locations;
            },
            validateField(e){
                if(e.target.classList.contains("optional")) return;
                let value = e.target.value;
                let valid = value != "" && value !== null;
                if(e.target.getAttribute('type') == "phone")
                    valid = /\+\d{9,13}/.test(value) && !/[^+0-9]/.test(value);
                if(e.target.getAttribute('type') == "email")
                    valid = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}/.test(value);
                if(valid){
                    e.target.classList.add('is-valid');
                    e.target.classList.remove('is-invalid');
                }
                else if(e.target.nodeName != "SELECT"){
                    e.target.classList.add('is-invalid');
                    e.target.classList.remove('is-valid');
                }
            },
            verifyVAT(event = null, fromNBD = false){
                const el = event ? event.target : document.getElementById('vatid');
                if(!el) return;
                if(event && event.target.value){
                    relationservice.checkForDuplicates(this.relation.id, event.target.value).then(response => this.relation.duplicate = response.data.duplicate);
                }
                const value = el.value.replaceAll(' ', '').replaceAll('-', '').replaceAll('.', '');
                const match = this.countries.find(item => item.id == this.relation.country_id);
                if(!match || value == '') return;
                const regexes = {
                    AL: /^[JKL][0-9]{8}[A-Z]$/,
                    AR: /^[0-9]{11}$/,
                    AT: /^ATU[0-9]{8}$/,
                    AU: /^[0-9]{11}$/,
                    BE: /^BE[0-9]{10}$/,
                    BG: /^BG[0-9]{8,9}$/,
                    BO: /^[0-9]{7}$/,
                    BR: /^[0-9]{14}$/,
                    BY: /^[0-9]{9}$/,
                    CA: /^[0-9]{9}$/,
                    CH: /^[0-9]{6}$|^CHE[0-9]{9}(TVA|MWST|IVA)$/,
                    CL: /^[0-9]{8}[0-9K]$/,
                    CO: /^[0-9]{10}$/,
                    CR: /^[0-9]{9,12}$/,
                    CY: /^CY[A-Z0-9]{8}[A-Z]$/,
                    CZ: /^CZ[0-9]{8,10}$/,
                    DE: /^DE[0-9]{9}$/,
                    DO: /^[145][0-9]{8}$/,
                    DK: /^DK[0-9]{8}$/,
                    EC: /^[0-9]{13}$/,
                    EE: /^EE[0-9]{9}$/,
                    ES: /^ES[A-Z0-9][0-9]{6}[A-Z0-9]$|^ES[AB][0-9]{7}[A-Z0-9]$/,
                    FI: /^FI[0-9]{8}$/,
                    FR: /^FR[A-Z0-9]{2}[0-9]{9}$/,
                    GB: /^GB[0-9]{9,12}$/,
                    GR: /^EL[0-9]{9}$/,
                    GT: /^[0-9]{8}$/,
                    HR: /^HR[0-9]{11}$/,
                    HU: /^HU[0-9]{8}$/,
                    ID: /^[0-9]{15}$/,
                    IE: /^IE[0-9]{7}[A-Z]{2}$/,
                    IL: /^[0-9]{9}$/,
                    IN: /^[0-9]{15}$/,
                    IS: /^[A-Z0-9]{5,6}$/,
                    IT: /^IT[0-9]{11}$/,
                    KZ: /^[0-9]{12}$/,
                    LT: /^LT[0-9]{9,12}$/,
                    LU: /^LU[0-9]{8}$/,
                    LV: /^LV[0-9]{11}$/,
                    MC: /^FR[0-9]{11}$/,
                    MK: /^MK[0-9]{13}$/,
                    MT: /^MT[0-9]{8}$/,
                    MX: /^...[0-9]{6}[A-Z]..$/,
                    NI: /^[0-9]{13}[A-Z]$/,
                    NL: /^NL[0-9]{9}B[0-9]{2}$/,
                    NO: /^[0-9]{9}MVA$/,
                    NZ: /^[0-9]{12}$/,
                    PE: /^[0-9]{11}$/,
                    PH: /^[0-9]{12}$/,
                    PL: /^PL[0-9]{10}$/,
                    PT: /^PT[0-9]{9}$/,
                    PY: /^[0-9]{7,9}$/,
                    RO: /^RO[0-9]{2,10}$/,
                    RS: /^[0-9]{9}$/,
                    RU: /^[0-9]{10}$/,
                    SA: /^[0-9]{15}$/,
                    SE: /^SE[0-9]{12}$/,
                    SI: /^SI[0-9]{8}$/,
                    SK: /^SK[0-9]{10}$/,
                    SM: /^[0-9]{5}$/,
                    SV: /^[0-9]{14}$/,
                    TR: /^[0-9]{10}$/,
                    UA: /^[0-9]{12}$/,
                    UY: /^[0-9]{12}$/,
                    UZ: /^[0-9]{9}$/,
                    VE: /^[EJGV][0-9]{9}$/
                };
                let regex = regexes[match.iso];
                if(regex && !regex.test(value)){
                    el.classList.add('is-invalid');
                    el.classList.remove('is-valid');
                    if(!fromNBD){
                        this.setNextButtonDisabled(2);
                        this.$forceUpdate();
                    }
                    return false;
                }
                else{
                    el.classList.add('is-valid');
                    el.classList.remove('is-invalid');
                }
                if(this.relation.country && this.relation.country.registration_id_same_as_vat_id){
                    this.relation.registration = value;
                }
                if(!fromNBD){
                    this.setNextButtonDisabled(2);
                    this.$forceUpdate();
                }
                return true;
            },
            fillSearch(list, value){
                const match = list.find(item => item.id == value);
                return match ? (match.name ? match.name : match.relation.name) : '';
            },
            checkRelationName(){
                if(!this.relation.name || this.isEditing) return;
                const request = new XMLHttpRequest();
                let url = 'https://search.nmtmainport.com/indexes/production_relations/search';
                if (!process.env.VUE_APP_IS_PRODUCTION) {
                    url = 'https://search.nmtmainport.com/indexes/staging_relations/search';
                }
                request.open('POST', url, true);
                request.setRequestHeader('Content-Type', 'application/json');
                const token = process.env.VUE_APP_MEILISEARCH_TOKEN;
                request.setRequestHeader('Authorization', 'Bearer ' + token);
                request.addEventListener('load', () => {
                    if(request.status >= 200 && request.status < 400){
                        const response = JSON.parse(request.responseText);
                        if(response && response.hits){
                            this.hits = [];
                            const hitContainer = response.hits.filter(hit => hit.type == this.relation.type && hit.deleted_at == null);
                            hitContainer.forEach(hit => {
                                if(this.hits.length >= 5) return;
                                if(!this.hits.find(h => h.name == hit.name)) this.hits.push(hit);
                            });
                        }
                    }
                    else{
                        console.log('error', request.statusText);
                    }
                });
                request.send(JSON.stringify({q: this.relation.name}));
            },
            selectHit(hit){
                if(confirm(this.$t('crm.hit_confirm'))){
                    this.reset();
                    relationservice.show(hit.id).then(response => {
                        this.setData(response.data);
                        window.location.hash = hit.id;
                    }).catch(error => {
                        console.log('error', error);
                    });
                }
            },
            handleFileChange(evt) {
                var files = evt.target.files || evt.dataTransfer.files;
                if (!files.length)  return;
                
                var file = files[0];    
                var reader  = new FileReader();
                reader.onload = (e) => {
                    const imgBase64Path = e.target.result;
                    this.relation.carrier.logo = imgBase64Path
                }

                if (file) reader.readAsDataURL(file);
            },
            checkData(){
                this.dataItemsLoaded++;
                if(this.dataItemsLoaded >= this.dataItemsToLoad){
                    if(this.isEditing){
                        this.setData(false);
                    }
                    else{
                        this.reset(true);
                    }
                }
            },
            loadData(){
                this.countries = dataStore.getters.getCountryNames;
                this.relationtypes = dataStore.getters.getRelationTypes;
                costservice.index().then(response => {
                    this.generalCosts = response.data
                    this.checkData();
                }).catch(error => {
                    console.log('error', error)
                });
                currencyservice.index().then(response => {
                    this.currencies = response.data
                    window.defaultCurrency = this.currencies.filter(currency => currency.is_default == 1)[0].id;
                    this.checkData();
                }).catch(error => {
                    console.log('error', error)
                })
                calculationunitservice.index().then(response => {
                    this.calculation_units = response.data.filter(unit => unit.id < 5);
                    this.checkData();
                }).catch(error => {
                    console.log('error', error)
                })
                paymentTermService.index().then(response => {
                    this.paymentTerms = response.data;
                    this.paymentTerms.forEach(term => {
                        term.name = term.days == 0 ? 'Payment Against Documents' : (term.days + ' days');
                    });
                    this.checkData();
                }).catch(error => {
                    console.log('error', error)
                });
                portService.index().then(response => {
                    this.ports = response.data //todo\
                }).catch(error => {
                    console.log('error', error)
                });
            }

        }
    }
</script>

<style scoped>
    .tab-pane {min-height: 300px}
    .col-sm-1p5{
        flex: 0 0 auto;
        width: 12%
    }
    .shipper-details .row{
        margin-bottom: 8px
    }
    .shipper-details .row > label:first-child{
        display: block;
        width: 30%
    }
    .shipper-details .row:not(.four-columns) > :not(label){
        width: 60%
    }
    .shipper-details .row > label:not(:first-child){
        display: block;
        width: 20%;
        padding: 4px 0 0 16px
    }
    .shipper-details .row.four-columns > :not(label){
        width: 20%
    }
    .trans-from label{
        padding-bottom: 9px
    }
</style>
<style>
    #form-Relation label.col-sm-4 { width: 20% !important }
</style>